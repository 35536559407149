function getTimeDiffInMilliseconds(endTime, timezone = 'UTC') {
  const currentDateTime = new Date();
  let endTimeDateTime = new Date(endTime);

  if (timezone !== 'UTC') {
    // Convert end time to the specified timezone
    const options = {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const endTimeFormatted = formatter.format(endTimeDateTime);

    endTimeDateTime = new Date(endTimeFormatted);
  }

  const timeDifferenceMs = endTimeDateTime - currentDateTime;

  return timeDifferenceMs;
}

export default getTimeDiffInMilliseconds;
