import { shortEmailErrorText, shortPasswordErrorText, startWithLetterErrorText } from 'constants/text/loginForm';
import { useContext, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { GET_COOKIE_URL, LOGIN_URL } from 'constants/apiRoutes';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import useFetch from 'hooks/useFetch';
import errorToast from 'components/toasts/errorToast';

const useLogIn = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [errorMessages, setErrorMessages] = useState(null);
  const { logIn } = useContext(AuthContext);

  const { fetchData, isLoading } = useFetch(LOGIN_URL, {
    method: 'POST',
    fetchOnMount: false,
    onSuccess: (data) => {
      const { user } = convertSnakeToCamelCase(data);
      logIn(user);
    },
    onError: (data) => {
      let message = '';
      if (data?.response?.data?.message) {
        message = data?.response?.data.message;
      } else {
        message = 'Нещо се обърка';
      }
      errorToast(message);
    },
  });

  const { fetchData: getCookie } = useFetch(GET_COOKIE_URL, {
    fetchOnMount: false,
    onSuccess: () => {
      fetchData({
        email: loginData.email,
        password: loginData.password,
      });
    },
  });

  const updateLoginData = (e) => {
    const { name, value } = e.target;

    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateData = () => {
    const messages = {};

    const { email, password } = loginData;

    const [emailName] = email.split('@');

    if (emailName.length < 3) {
      messages.email = shortEmailErrorText;
    }

    if (!/[a-zA-Z]/.test(emailName[0])) {
      messages.email = startWithLetterErrorText;
    }

    if (password.length < 8) {
      messages.password = shortPasswordErrorText;
    }

    setErrorMessages(messages);

    // if message s = {} means there is no error
    return Object.keys(messages).length === 0;
  };
  const submitLoginForm = (e) => {
    e.preventDefault();

    const areValid = validateData();

    if (areValid) {
      getCookie();
    }
  };

  return {
    loginData,
    errorMessages,
    isLoading,
    updateLoginData,
    submitLoginForm,
  };
};

export default useLogIn;
