const getOrderedPlayers = (players, user) => {
  let startIndex = 0;
  const oredered = [];
  for (let i = 0; i < players.length; i += 1) {
    if (players[i].userId === user.id) {
      oredered.push(players[i]);
      startIndex = i;
    }
  }

  let len = players.length;
  while (len > 0) {
    startIndex = startIndex + 1 === players.length ? 0 : startIndex + 1;

    if (players[startIndex].userId !== user.id) {
      oredered.push(players[startIndex]);
    }
    len -= 1;
  }

  return oredered;
};

export default getOrderedPlayers;
