const TOURNAMENT_STATES = {
  STANDART_QUESTION_NUMBER_CHOOSING: 1,
  TOPIC_ANNOUNCEMENT: 2,
  FIRST_BETTING: 3,
  QUESTION_ANNOUNCEMENT: 4,
  SECOND_BETTING: 5,
  RIGHT_QUESTION_CHECKING: 6,
  TIEBREAK_QUESTION_NUMBER_CHOOSING: 7,
  TIEBREAK_QUESTION_ANNOUNCEMENT: 8,
  TIEBREAK_QUESTION_ANSWERING: 9,
};

export default TOURNAMENT_STATES;
