import React from 'react';
import FormImageWrapper from 'components/Forms/FormImageWrapper';
import useLogIn from 'hooks/useLogIn';
import BackButton from 'components/Buttons/BackButton';
import {
  emailLabelText, formHeaderText, passwordLabelText, submitButtonText,
} from 'constants/text/loginForm';
import useProtected from 'hooks/useProtected';
import Loading from 'components/Loading/Loading';
import {
  Button, Stack, TextField, Typography, Link, Box,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { blue } from '@mui/material/colors';

function Login() {
  const {
    loginData,
    errorMessages,
    isLoading,
    updateLoginData,
    submitLoginForm,
  } = useLogIn();

  const { isLoading: isStillFetching } = useProtected({ redirectUrl: '/user', hasToBeLogged: false });

  if (isLoading || isStillFetching) {
    return <Loading />;
  }

  return (
    <FormImageWrapper>
      <Box sx={{ maxHeight: '100hv', overflowY: 'auto' }}>
        <form style={{ padding: '2em' }} onChange={updateLoginData} onSubmit={submitLoginForm}>
          <BackButton />
          <Stack spacing={3}>
            <Typography variant="h5" textAlign="center">{formHeaderText}</Typography>
            <TextField
              fullWidth
              label={emailLabelText}
              variant="outlined"
              defaultValue={loginData.email}
              name="email"
              error={!!errorMessages?.email}
              helperText={errorMessages?.email}
              type="email"
            />

            <TextField
              fullWidth
              label={passwordLabelText}
              variant="outlined"
              defaultValue={loginData.email}
              name="password"
              error={!!errorMessages?.password}
              helperText={errorMessages?.password}
              type="password"
            />
            <Button variant="contained" type="submit">{submitButtonText}</Button>
          </Stack>
        </form>

        <Typography textAlign="center">
          Нямаш акаунт?
          {' '}
          {' '}
          <Link color={blue[500]} variant="body2" to="/registration" component={RouterLink}>
            Регистрирай се тук!
          </Link>
        </Typography>
      </Box>
    </FormImageWrapper>
  );
}

export default Login;
