const avatarValues = [
  { url: '/images/avatars/avatar-1.png', id: 1 },
  { url: '/images/avatars/avatar-2.png', id: 2 },
  { url: '/images/avatars/avatar-3.png', id: 3 },
  { url: '/images/avatars/avatar-4.png', id: 4 },
  { url: '/images/avatars/avatar-5.png', id: 5 },
  { url: '/images/avatars/avatar-6.png', id: 6 },
  { url: '/images/avatars/avatar-7.png', id: 7 },
  { url: '/images/avatars/avatar-8.png', id: 8 },
  { url: '/images/avatars/avatar-9.png', id: 9 },
];

export default avatarValues;
