import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import { TextField } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function RaiseInput() {
  const { raise, setRaise } = useContext(BettingContext);

  const isMobile = useIsMobile();

  const handleKeyPress = (event) => {
    // when ENTER is pressed triger Raise Button press or if betting trigger Bet button press
    if (event.keyCode === 13) {
      event.preventDefault();
      const raiseButton = document.getElementById('raise_button');
      if (raiseButton) {
        raiseButton.click();
        return;
      }

      const betButton = document.getElementById('bet_button').click();
      if (betButton) {
        betButton.click();
      }
    }
  };

  const updateRaise = (e) => {
    setRaise((prevRaise) => ({ ...prevRaise, value: e.target.value }));
  };

  const validateRaise = () => {
    if (raise.value < raise.min || raise.value > raise.max) {
      setRaise((prevRaise) => ({ ...prevRaise, value: raise.min }));
    }
  };

  return (
    <TextField
      type="number"
      onChange={updateRaise}
      onBlur={validateRaise}
      onKeyUp={handleKeyPress}
      value={Math.floor(raise.value)}
      data-testid="raise_input"
      id="raise_input"
      fullWidth
      size="small"
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        min: raise.min,
        max: raise.max,
        style: {
          height: isMobile ? 'auto' : null,
        },
      }}
    />
  );
}

export default RaiseInput;
