import { useTheme, useMediaQuery } from '@mui/material';
import React from 'react';

const useIsMobile = () => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down('md'));
};

export default useIsMobile;
