function getTimeDiffInMinutes(endTime, timezone = 'UTC') {
  const currentDateTime = new Date();
  let endTimeDateTime = new Date(endTime);

  if (timezone !== 'UTC') {
    // Convert end time to the specified timezone
    const options = {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const endTimeFormatted = formatter.format(endTimeDateTime);
    endTimeDateTime = new Date(endTimeFormatted);
  }

  const timeDifferenceMs = endTimeDateTime - currentDateTime;
  const timeDifferenceMinutes = Math.floor(timeDifferenceMs / (1000 * 60));
  const timeDifferenceSeconds = Math.floor((timeDifferenceMs / 1000) % 60);

  if (timeDifferenceMinutes < 0 && timeDifferenceSeconds < 0) {
    return '00:00';
  }

  if (timeDifferenceSeconds < 10) {
    return `${timeDifferenceMinutes}:0${timeDifferenceSeconds}`;
  }

  return `${timeDifferenceMinutes}:${timeDifferenceSeconds}`;
}

export default getTimeDiffInMinutes;
