/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
const convertSnakeToCamelCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(convertSnakeToCamelCase);
  } if (obj && typeof obj === 'object') {
    const convertedObj = {};
    for (const [key, value] of Object.entries(obj)) {
      const camelCaseKey = key.replace(/_\w/g, (m) => m[1].toUpperCase());
      convertedObj[camelCaseKey] = convertSnakeToCamelCase(value);
    }
    return convertedObj;
  }
  return obj;
};

export default convertSnakeToCamelCase;
