import React from 'react';
import CheckButton from 'components/Buttons/CheckButton';
import BetButton from 'components/Buttons/BetButton';
import RaiseInput from 'components/Inputs/RaiseInput';
import { Grid, Stack } from '@mui/material';

function BetAndCheckButtons() {
  const buttonMobileStyles = {
    fontSize: '11px', p: 0, m: 0,
  };
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Stack direction="row">
          <RaiseInput />
          <BetButton buttonMobileStyles={buttonMobileStyles} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <CheckButton buttonMobileStyles={buttonMobileStyles} />
      </Grid>
    </Grid>
  );
}

export default BetAndCheckButtons;
