import React, { useContext, useEffect, useState } from 'react';
import CallButton from 'components/Buttons/CallButton';
import FoldButton from 'components/Buttons/FoldButton';
import RaiseButton from 'components/Buttons/RaiseButton';
import TournamentContext from 'context/TournamentContext';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';
import CheckButton from 'components/Buttons/CheckButton';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import RaiseInput from 'components/Inputs/RaiseInput';
import getNextPlayer from 'utilis/getNextPlayer';
import { Grid, Stack } from '@mui/material';

function RaiseAndCallButtons() {
  const [showCheckButton, setShowCheckButton] = useState(false);

  const { tournament } = useContext(TournamentContext);

  const { players } = tournament;
  const { stateId } = tournament.handState;

  useEffect(() => {
    // if BB is the last raise and no one has raised the bet then BB must check or raise
    const { currentPlayer } = getCurrentPlayerAndHighestBet(players);

    if (showCheckButton === true) {
      setShowCheckButton(false);
    }

    if (players.length === 2
       && (currentPlayer.isSmallBlind === true || currentPlayer.isSmallBlind === 1)) {
      if (currentPlayer.currentStack === 0) {
        setShowCheckButton(true);
      }
    }

    if (stateId !== TOURNAMENT_STATES.FIRST_BETTING) {
      return;
    }

    if (!currentPlayer.isBigBlind) {
      return;
    }

    const { currentLevel } = tournament.tournamentState;
    const [, bigBlindString] = currentLevel.split('/');

    const nextPlayer = getNextPlayer(players);
    if (nextPlayer.isLastRaiser) {
      if (nextPlayer.raiseSize === Number(bigBlindString)
      && nextPlayer.currentBet <= Number(bigBlindString)) {
        setShowCheckButton(true);
      }
    }
  }, [players]);

  const buttonMobileStyles = {
    fontSize: '11px', p: 0, m: 0, minHeight: '40px', whiteSpace: 'no-wrap',
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {showCheckButton === false
      && (
        <Grid item xs={6}>
          <FoldButton buttonMobileStyles={buttonMobileStyles} />
        </Grid>
      ) }

      {showCheckButton === false && (
      <Grid item xs={6}>
        <CallButton buttonMobileStyles={buttonMobileStyles} />
      </Grid>
      ) }

      {showCheckButton === true && (
      <Grid item xs={12}>
        <CheckButton buttonMobileStyles={buttonMobileStyles} />
      </Grid>
      ) }

      <Grid item xs={12}>
        <Stack direction="row">
          <RaiseInput />
          <RaiseButton buttonMobileStyles={buttonMobileStyles} />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default RaiseAndCallButtons;
