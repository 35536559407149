import React from 'react';
import FormImageWrapper from 'components/Forms/FormImageWrapper';
import ImageSelect from 'components/Inputs/ImageSelect';
import useRegistration from 'hooks/useRegistration';
import BackButton from 'components/Buttons/BackButton';
import Loading from 'components/Loading/Loading';
import {
  chooseAvatarLabelText,
  emailLabelText,
  formHeaderText,
  passwordLabelText,
  submitButtonText,
  usernameLabelText,
  repeatedPasswordLabelText,
} from 'constants/text/registrationForm';
import useProtected from 'hooks/useProtected';
import avatarValues from 'constants/avatarValues';
import {
  Typography, Stack, TextField, Button, Link, Box,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { blue } from '@mui/material/colors';

function Registration() {
  const {
    userData,
    errorMessages,
    isLoading,
    updateAvatarImage,
    updateUserData,
    submitRegistration,
  } = useRegistration();

  const { isLoading: isStillFetching } = useProtected({ redirectUrl: '/user', hasToBeLogged: false });

  if (isLoading || isStillFetching) {
    return <Loading />;
  }

  return (
    <FormImageWrapper>
      <Box sx={{ maxHeight: '100vh', overflowY: 'auto' }}>
        <form style={{ padding: '2em' }} onSubmit={submitRegistration} onChange={updateUserData}>
          <BackButton />
          <Stack spacing={3}>
            <Typography textAlign="center" variant="h5">{formHeaderText}</Typography>
            <TextField
              fullWidth
              label={emailLabelText}
              variant="outlined"
              defaultValue={userData.email}
              name="email"
              error={!!errorMessages?.email}
              helperText={errorMessages?.email}
              type="email"
            />

            <TextField
              fullWidth
              label={usernameLabelText}
              variant="outlined"
              defaultValue={userData.username}
              name="username"
              error={!!errorMessages?.username}
              helperText={errorMessages?.username}
              type="text"
            />

            <TextField
              fullWidth
              label={passwordLabelText}
              variant="outlined"
              defaultValue={userData.password}
              name="password"
              error={!!errorMessages?.password}
              helperText={errorMessages?.password}
              type="password"
            />

            <TextField
              fullWidth
              label={repeatedPasswordLabelText}
              variant="outlined"
              defaultValue={userData.repeatedPassword}
              name="repeatedPassword"
              error={!!errorMessages?.repeatedPassword}
              helperText={errorMessages?.repeatedPassword}
              type="password"
            />

            <Typography variant="h5" textAlign="center" style={{ marginTop: '2em' }}>{chooseAvatarLabelText}</Typography>

            <ImageSelect
              values={avatarValues}
              avatarId={userData.imageId}
              updateAvatarImage={updateAvatarImage}
            />
            <Button variant="contained" fullWidth type="submit">{submitButtonText}</Button>
          </Stack>
        </form>
        <Typography textAlign="center" mb={3}>
          Имаш акаунт?
          {' '}
          {' '}
          <Link color={blue[500]} variant="body2" to="/login" component={RouterLink}>
            Влез тук!
          </Link>
        </Typography>
      </Box>
    </FormImageWrapper>
  );
}

export default Registration;
