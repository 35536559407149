import React from 'react';
import {
  CircularProgress,
  Divider,
  Paper, Stack, Typography,
} from '@mui/material';

function EmptyMessage({
  type,
}) {
  const typeText = type === 'standard' ? 'стандартни' : 'тайбрейк';
  return (
    <Typography textAlign="center">
      Няма зададени
      {' '}
      {typeText}
      {' '}
      въпроси
    </Typography>
  );
}
function AskedQuestionsList({
  title,
  items,
  type = 'standard',
  isLoading = false,
}) {
  const questions = items?.filter((item) => item.type === type) ?? [];

  return (
    <>
      <Typography variant="h5" textAlign="center">{title}</Typography>
      <Paper
        elevation={3}
        sx={{
          maxHeight: '50vh',
          p: 2,
          overflowY: 'auto',
        }}
      >
        {
            isLoading
              ? (
                <Stack justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Stack>
              )
              : (
                <Stack
                  spacing={1}
                  divider={
                    <Divider flexItem />
                    }
                >
                  {questions.length === 0
                    && <EmptyMessage type={type} />}
                  {
                        questions.map((question) => (
                          <Stack
                            sx={{
                              width: '100%',
                            }}
                            direction="row"
                            key={question.id}
                            spacing={2}
                          >
                            <Typography>
                              {question.question_number}
                              .
                            </Typography>
                            <Typography
                              sx={{
                                wordWrap: 'break-word',
                              }}
                            >
                              {question.question_text}
                            </Typography>
                          </Stack>
                        ))
          }
                </Stack>
              )
        }
      </Paper>
    </>
  );
}

export default AskedQuestionsList;
