import React, { useContext } from 'react';
import { foldButtonText } from 'constants/text/dashboard';
import GAME_MOVES from 'constants/gameMoves';
import BettingContext from 'context/BettingContext';
import { LoadingButton } from '@mui/lab';
import useIsMobile from 'hooks/useIsMobile';

function FoldButton({ buttonMobileStyles }) {
  const isMobile = useIsMobile();
  const { isRequestResolved, handleMove } = useContext(BettingContext);
  return (
    <LoadingButton
      onClick={() => handleMove({ move: GAME_MOVES.FOLD })}
      loading={isRequestResolved === false}
      id="fold_button"
      type="button"
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}
    >
      {foldButtonText}
    </LoadingButton>
  );
}

export default FoldButton;
