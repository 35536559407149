import React from 'react';
import Loading from 'components/Loading/Loading';
import useTournamentForm from 'hooks/useTournamentForm';
import TournamentStatsForm from 'components/Forms/TournamentStatsForm';
import useProtected from 'hooks/useProtected';

function SetupTournamentPage() {
  const {
    isLoading,
    tournamentData,
    updateTournamentData,
    submitTournamentData,
  } = useTournamentForm();

  const { isLoading: isStillFetching } = useProtected({ redirectUrl: '/', hasToBeLogged: true });

  if (isLoading || isStillFetching) {
    return <Loading />;
  }

  return (
    <div className="setup_game_page_body">
      <TournamentStatsForm
        tournamentData={tournamentData}
        updateTournamentData={updateTournamentData}
        onSubmit={submitTournamentData}
      />
    </div>
  );
}

export default SetupTournamentPage;
