import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from 'context/AuthContext';

const useProtected = ({ redirectUrl, hasToBeLogged }) => {
  const navigate = useNavigate();
  const { isLoading } = useContext(AuthContext);
  const isLogged = JSON.parse(localStorage.getItem('isLogged'));

  useEffect(() => {
    // could be done in signle if but i think it's more readable like that
    if (hasToBeLogged) {
      if (!isLogged && isLoading === false) {
        navigate(redirectUrl);
      }
    }

    if (hasToBeLogged === false) {
      if (isLogged === true && isLoading === false) {
        navigate(redirectUrl);
      }
    }
  }, [isLogged, isLoading]);

  return { isLoading };
};

export default useProtected;
