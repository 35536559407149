import TOURNAMENT_STATES from 'constants/tournamentStates';

const getPlayerClasses = (player, stateId) => {
  let playerClassNames = 'player';

  if (player.isCurrent === true || player.isCurrent === 1) {
    if (stateId === TOURNAMENT_STATES.FIRST_BETTING
       || stateId === TOURNAMENT_STATES.SECOND_BETTING) {
      playerClassNames += ' current';
    }
  }

  if (player.inHand === false || player.inHand === 0) {
    playerClassNames += ' folded';
  }

  if (player.inPlay === false || player.inPlay === 0) {
    playerClassNames += ' lost';
  }

  return playerClassNames;
};

export default getPlayerClasses;
