import {
  useEffect, useMemo, useState, useContext,
} from 'react';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import Pusher from 'pusher-js';
import { TOURNAMENTS_URL } from 'constants/apiRoutes';
import AuthContext from 'context/AuthContext';

import useFetch from 'hooks/useFetch';
import TOURNAMENT_STATUS from 'constants/tournamentStatus';

const useTournamentStats = () => {
  const [tournaments, setTournaments] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useContext(AuthContext);

  const ownedByMeTournaments = useMemo(
    () => tournaments.filter((tournament) => tournament?.owner?.id === user?.id),
    [tournaments, user?.id],
  );

  const tournamentWhereIPlay = useMemo(
    () => tournaments.filter((tournament) => {
      if (!tournament?.players?.length) {
        return false;
      }

      const amIPlaying = tournament?.players
        .find((player) => player?.userId === user?.id);

      return amIPlaying;
    }),
    [tournaments, user?.id],
  );

  const everyOtherTournament = useMemo(() => tournaments.filter((tournament) => {
    const isOwnedByMe = ownedByMeTournaments.find(
      (ownedTournament) => ownedTournament.uniqueId === tournament.uniqueId,
    );

    const amIPlaying = tournamentWhereIPlay.find(
      (playingInTournament) => playingInTournament.uniqueId === tournament.uniqueId,
    );

    const isTournamentStarted = tournament.tournamentStatusId === TOURNAMENT_STATUS.IN_GAME;
    return !isOwnedByMe && !amIPlaying && !isTournamentStarted;
  }), [tournaments, ownedByMeTournaments, tournamentWhereIPlay]);

  const { error, isLoading } = useFetch(TOURNAMENTS_URL, {
    onSuccess: (data) => {
      setTournaments(convertSnakeToCamelCase(data.data));
      setIsSuccess(true);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      // Pusher.logToConsole = true;

      const pusher = new Pusher(process.env.REACT_APP_KEY, {
        cluster: process.env.REACT_APP_CLUSTER,
      });

      const channel = pusher.subscribe('quiz-game');

      channel.bind('App\\Events\\CreatedTournament', (data) => {
        const { tournament } = convertSnakeToCamelCase(data);
        setTournaments((prevTournaments) => [...prevTournaments, tournament]);
      });

      channel.bind('App\\Events\\UpdatePlayers', (data) => {
        const { players, tournamentId } = convertSnakeToCamelCase(data);
        setTournaments((prevTournaments) => {
          const updatedTournaments = prevTournaments.map((item) => {
            if (item.uniqueId === tournamentId) {
              return {
                ...item,
                players,
              };
            }
            return item;
          });
          return updatedTournaments;
        });
      });

      channel.bind('App\\Events\\RemoveTournamentFromDashboard', (data) => {
        const { tournamentId } = convertSnakeToCamelCase(data);
        setTournaments((prevTournaments) => prevTournaments.filter(
          (tournament) => tournament.uniqueId !== tournamentId,
        ));
      });
    }
  }, [isSuccess]);

  return {
    ownedByMeTournaments,
    tournamentWhereIPlay,
    everyOtherTournament,
    isLoading,
    error,
  };
};

export default useTournamentStats;
