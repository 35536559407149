import React, { useEffect, useRef } from 'react';
import applyBetAnimation from 'utilis/applyBetAnimation';

function PlayerBet({ player }) {
  const isInitialRender = useRef(true);
  const playerBetRef = useRef(null);
  useEffect(() => {
    // prevents animation on first render, if in React.StrictMode this will not work
    if (isInitialRender.current === true) {
      isInitialRender.current = false;
    } else {
      applyBetAnimation(playerBetRef);
    }
  }, [player.currentStack]);
  return (
    <>
      {
            player.currentBet !== null && player.currentBet > 0

            && (
            <div ref={playerBetRef} className="player_bet">
              <img src="/images/coins_on_table.png" alt="player bet" />
              { Math.floor(player.currentBet)}
            </div>
            )
        }
    </>
  );
}

export default PlayerBet;
