import {
  Button, Dialog, DialogContent, DialogTitle, Stack, TextField,
} from '@mui/material';
import React from 'react';
import useIsOwner from 'hooks/useIsOwner';

function UploadQuestionsForm({
  defaultValues,
  onClose,
  onSave,
  open,
}) {
  const isOwner = useIsOwner();

  if (!isOwner) {
    return null;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    onSave(formData.get('questions'));
  };

  const formatDefaultValues = (values) => values.map((question) => `${question.text} \n \n`).join('');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{
        maxWidth: 'auto',
      }}
    >
      <DialogTitle textAlign="center">
        Напиши своите въпроси
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: '40vw',
        }}
      >
        <form onSubmit={onSubmit}>
          <Stack
            spacing={3}
          >
            <TextField
              multiline
              minRows={12}
              maxRows={12}
              name="questions"
              defaultValue={formatDefaultValues(defaultValues)}
            />

            <Stack direction="row" spacing={2}>
              <Button onClick={onClose} fullWidth variant="outlined">Отказ</Button>
              <Button type="submit" fullWidth variant="contained">Запази</Button>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UploadQuestionsForm;
