import React, { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import TournamentContext from 'context/TournamentContext';

const useIsOwner = () => {
  const { user } = useContext(AuthContext);
  const { tournament } = useContext(TournamentContext);
  const { owner } = tournament;

  return (user.id === owner.id);
};

export default useIsOwner;
