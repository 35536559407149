import {
  Button,
  Paper,
} from '@mui/material';
import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';

function TopToolbar({
  setShowUploadQuestionsMenu,
}) {
  const showUploadQuestionsMenu = () => {
    setShowUploadQuestionsMenu(true);
  };
  return (
    <Paper
      elevation={2}
      sx={{
        position: 'absolute',
        top: 10,
      }}
    >
      <Button
        onClick={showUploadQuestionsMenu}
        variant="contained"
        endIcon={(
          <FileUploadIcon />
          )}
      >
        Качи въпроси
      </Button>

    </Paper>
  );
}

export default TopToolbar;
