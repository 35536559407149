const positionsOnTable = [
  [0, 5],
  [0, 4, 5],
  [8, 1, 4, 5],
  [0, 2, 4, 5, 7],
  [8, 2, 3, 4, 6, 7],
  [0, 2, 3, 4, 5, 6, 7],
  [8, 1, 2, 3, 4, 5, 6, 7],
  [0, 1, 2, 3, 4, 5, 6, 7, 8],
];

export default positionsOnTable;
