import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserPage from 'pages/UserPage';
import WinnerPage from 'pages/WinnerPage';
import NotFoundPage from 'pages/NotFoundPage';
import SetupTournamentPage from 'pages/SetupTournamentPage';
import TournamentRoom from 'pages/TournamentRoom';
import Registration from 'pages/Registration';
import Login from 'pages/Login';
import MainPage from 'pages/MainPage';
import AuthContext from 'context/AuthContext';
import useAuth from 'hooks/useAuth';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoinTournamentSection from 'components/User/JoinTournamentSection';
import RulesModal from 'components/Modals/RulesModal';

function App() {
  const {
    isLogged, logIn, logOut, user, isLoading,
  } = useAuth();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#6658D3',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
  });

  return (
    <div className="App">
      <AuthContext.Provider value={{
        user, isLogged, logIn, logOut, isLoading,
      }}
      >
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/404" element={<NotFoundPage />} />

            <Route path="/" element={<MainPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/user" element={<UserPage />}>
              <Route path="tournaments" element={<JoinTournamentSection />} />
              <Route path="rules" element={<RulesModal />} />
            </Route>
            <Route path="/createTournament" element={<SetupTournamentPage />} />
            <Route path="/winner/:tournamentId" element={<WinnerPage />} />
            <Route path="/tournament/:tournamentId" element={<TournamentRoom />} />
            <Route path="/tournament/:tournamentId/:auto" element={<TournamentRoom />} />

          </Routes>
          <ToastContainer />
          <CssBaseline />
        </ThemeProvider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
