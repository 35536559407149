import React, { useEffect, useRef } from 'react';

function PlayerOption({ player, index, handleCheckboxChange }) {
  const answerRef = useRef();

  const ANIMATION_DURATION = 1000;

  useEffect(() => {
    if (answerRef.current && player.answer) {
      answerRef.current.classList.add('animation_class');

      setTimeout(() => {
        answerRef.current.classList.remove('animation_class');
      }, ANIMATION_DURATION);
    }
  }, [player.answer]);

  return (
    <div key={player.id} data-testid="player">
      <div className="holder">
        <label htmlFor={`right_player-${index}`}>
          <img src={`/images/avatars/avatar-${player.avatarId}.png`} alt="player avatar" />
          <p className="modal_player_name">{player.name}</p>
        </label>
        <input
          name={player.id}
          id={`right_player-${index}`}
          type="checkbox"
          onChange={handleCheckboxChange}
        />
      </div>
      <div ref={answerRef} className="answer">
        {player.answer || 'Няма отговор'}
      </div>
    </div>
  );
}

export default PlayerOption;
