import React from 'react';
import { useNavigate } from 'react-router-dom';
import useProtected from 'hooks/useProtected';
import Loading from 'components/Loading/Loading';
import {
  Typography, Stack, Button, useTheme, useMediaQuery, Box, Paper,
} from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function MainPage() {
  const { isLoading } = useProtected({ redirectUrl: '/user', hasToBeLogged: false });
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isPortrait = useMediaQuery('(orientation: portrait)');

  const IsSmall = isMobile || isPortrait;
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box className="main_page">
      <Stack sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Paper
          sx={{
            width: IsSmall ? '100%' : '70%',
            height: IsSmall ? '100%' : '70%',
            borderRadius: IsSmall ? 0 : '30px',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
          }}
        >
          <Stack className="inner_holder" direction={IsSmall ? 'column' : 'row'} justifyContent="center" alignItems="center">
            <Box width="50%">
              <Typography textAlign="center" variant="h5" fontWeight={600}>Здравей!</Typography>
              <Typography textAlign="center" color="grey" fontWeight={600}>Влез в акаунта си или се регистрирай!</Typography>
              <Stack spacing={2} width={IsSmall ? '100%' : '50%'} alignItems="center" justifyContent="center" sx={{ m: 'auto', mt: 6 }}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => { navigate('/login'); }}
                  startIcon={
                    <LockOpenIcon />
                  }
                >
                  Влизане
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<PersonAddIcon />}
                  onClick={() => { navigate('/registration'); }}

                >
                  Регистрация
                </Button>
              </Stack>

            </Box>
            {!IsSmall
              && (
              <Box width="50%">
                <Typography
                  textAlign="center"
                  mb={3}
                  fontSize={{ xs: '13px', sm: '16px', md: '20px' }}
                  fontWeight={600}
                >
                  Лигата на знаещите

                </Typography>
                <Typography
                  textAlign="center"
                  sx={{
                    width: '70%',
                    m: 'auto',
                    color: 'grey',
                    fontWeight: '600',
                  }}
                >
                  Покажете своите умения,
                  научете нещо ново и се забавлявайте безкрайно с нашата уникална игра.
                </Typography>
              </Box>
              )}
          </Stack>
        </Paper>
      </Stack>

    </Box>
  );
}

export default MainPage;
