import React, { useRef, useLayoutEffect } from 'react';
import { historyLogsHeadingText } from 'constants/text/dashboard';
import { Box, Divider, Typography } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function HistoryLog({
  historyLogs,
  title,
  disableAutoScroll = false,
}) {
  const endOfLogRef = useRef(null);
  const isMobile = useIsMobile();

  useLayoutEffect(() => {
    if (disableAutoScroll) {
      return;
    }

    if (endOfLogRef.current) {
      endOfLogRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [historyLogs]);

  return (
    <>
      {title || (
      <Typography
        mt={isMobile ? 1 : 2}
        fontSize={{ md: '16px', xs: '11px' }}
        textAlign="center"
      >
        {historyLogsHeadingText}
      </Typography>
      )}

      <Box
        flexGrow={1}
        sx={{
          overflowY: 'scroll', flex: '1 1 0', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', my: isMobile ? '6px' : 2, p: isMobile ? 1 : 2, borderRadius: '8px',
        }}
        data-testid="history"
      >
        {
          historyLogs.map((log) => (
            <Box py={0.5} key={log.id}>
              <Typography fontSize={{ md: '16px', xs: '11px' }}>{log.description}</Typography>
              <Divider />
            </Box>
          ))
        }
        <p ref={endOfLogRef} />
      </Box>
    </>
  );
}

export default React.memo(HistoryLog);
