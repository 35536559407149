const getNextPlayer = (players) => {
  let currentPlayerIndex = 0;

  for (let i = 0; i < players.length; i += 1) {
    if (players[i].isCurrent === 1 || players[i].isCurrent === true) {
      currentPlayerIndex = i;
    }
  }

  let nextPlayerIndex = currentPlayerIndex + 1 === players.length ? 0 : currentPlayerIndex + 1;
  let nextPlayer = null;

  while (nextPlayer === null) {
    if ((players[nextPlayerIndex].inHand === 1 || players[nextPlayerIndex].inHand === true)
          && (players[nextPlayerIndex].inPlay === 1 || players[nextPlayerIndex].inPlay === true)
    ) {
      nextPlayer = players[nextPlayerIndex];
    }

    nextPlayerIndex = nextPlayerIndex + 1 === players.length ? 0 : nextPlayerIndex + 1;
  }

  return nextPlayer;
};

export default getNextPlayer;
