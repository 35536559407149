import TOURNAMENT_FORMATS from 'constants/tournamentFormats';

const getTournamentType = (tournamentFormatId) => {
  if (tournamentFormatId === TOURNAMENT_FORMATS.LIMIT) {
    return 'Limit';
  }
  if (tournamentFormatId === TOURNAMENT_FORMATS.NO_LIMIT) {
    return 'No Limit';
  }
  if (tournamentFormatId === TOURNAMENT_FORMATS.POT_LIMIT) {
    return 'Pot limit';
  }
  return 'Error';
};

export default getTournamentType;
