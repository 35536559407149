/* eslint-disable no-mixed-operators */
import React, { useContext, useEffect, useRef } from 'react';
import spawnParticle from 'utilis/spawnParticle';
import BettingContext from 'context/BettingContext';
import { Stack, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import useIsMobile from 'hooks/useIsMobile';
import TournamentContext from 'context/TournamentContext';

function RaiseSlider() {
  const { raise, setRaise } = useContext(BettingContext);
  const isMobile = useIsMobile();

  const { tournament } = useContext(TournamentContext);
  const SB = tournament.tournamentState.currentLevel.split('/')[0];

  const particleContainerRef = useRef(null);
  const inputRef = useRef(null);
  const mouseCords = useRef({ x: null, y: null });

  const updateMouseCords = (event) => {
    mouseCords.current.x = event.clientX;
    mouseCords.current.y = event.clientY;
  };

  const handleStylesChangeOnInput = () => {
    // updates the line behind the slider thumb and spawn particles
    const { min } = inputRef.current;
    const { max } = inputRef.current;
    const val = inputRef.current.value;

    inputRef.current.style.backgroundSize = `${(val - min) * 100 / (max - min)}% 100%`;
  };

  const updateRaise = (e) => {
    setRaise((prevRaise) => ({ ...prevRaise, value: Number(e.target.value) }));
    const { x } = mouseCords.current;
    const { y } = mouseCords.current;
    spawnParticle(particleContainerRef.current, x, y);
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateMouseCords);
    return () => {
      document.removeEventListener('mousemove', updateMouseCords);
    };
  }, []);

  useEffect(() => {
    handleStylesChangeOnInput();
  }, [raise.value]);

  return (
    <Stack
      direction="row"
      sx={{
        marginTop: isMobile ? 0 : 2,
        marginBottom: isMobile ? 0 : 2,
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Typography mr={2}>{Math.floor(raise.min)}</Typography>
      <Slider
        ref={inputRef}
        onChange={updateRaise}
        valueLabelDisplay="auto"
        value={Number(raise.value) || 0}
        min={raise.min || 0}
        max={raise.max || 0}
        step={Number(SB)}
      />
      <Typography ml={2}>
        {Math.floor(raise.max)}
      </Typography>
      <div ref={particleContainerRef} className="slider_particle_container" />
    </Stack>
  );
}

export default RaiseSlider;
