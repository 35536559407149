import React, { useContext, useEffect, useState } from 'react';
import getPlayerClasses from 'utilis/getPlayerClasses';
import PlayerBet from 'components/Table/PlayerBet';
import PlayerBlint from 'components/Table/PlayerBlint';
import TournamentContext from 'context/TournamentContext';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import Timer from 'components/Timer/Timer';
import PaidIcon from '@mui/icons-material/Paid';
import { yellow } from '@mui/material/colors';
import useIsOwner from 'hooks/useIsOwner';

function Player({
  player, index, playerPosition, isButtonPosition,
}) {
  const { tournament } = useContext(TournamentContext);
  const { stateId } = tournament.handState || TOURNAMENT_STATES.TOPIC_ANNOUNCEMENT;

  const [showTimer, setShowTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(player.secondsLeft);

  const playerClassNames = getPlayerClasses(player, stateId);

  const isOwner = useIsOwner();

  useEffect(() => {
    // decieds wheather or not to show the timer
    if (!player.isCurrent) {
      setShowTimer(false);
      return;
    }

    if (stateId !== TOURNAMENT_STATES.FIRST_BETTING
         && stateId !== TOURNAMENT_STATES.SECOND_BETTING) {
      setShowTimer(false);
      return;
    }

    if (player.secondsLeft > 0) {
      setTimeLeft(player.secondsLeft);
      setShowTimer(true);
    }
  }, [player, stateId]);

  return (
    <div
      className={playerClassNames}
      id={`player-${playerPosition}`}
      data-testid={`player-${index}`}
    >

      <PlayerBlint player={player} />
      { index === 0 && !isOwner && <div className="you_indication">Аз</div> }
      { isButtonPosition === true && <img src="/images/button.png" alt="button" className="button" /> }
      { showTimer && (
      <Timer
        initialTime={timeLeft}
        onTimeOut={() => { setShowTimer(false); }}
        maxTime={timeLeft}
        options={{
          top: true,
        }}
      />
      )}

      <PlayerBet player={player} />

      <img
        className="player_avatar"
        src={`/images/avatars/avatar-${player.avatarId}.png`}
        alt="player avatar"
      />
      <p className="player_name">
        {player.name}
      </p>
      <div className="flex">
        <p>{Math.floor(player.currentStack)}</p>
        <PaidIcon
          sx={{
            color: yellow[800],
            fontSize: { xs: '13px', lg: '25px' },
          }}
        />
      </div>
    </div>
  );
}
export default React.memo(Player);
