import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BackButton({ backUrl }) {
  const backLink = backUrl || '/';
  return (
    <Link to={backLink}>
      <IconButton>
        <ArrowBackIcon />
      </IconButton>
    </Link>
  );
}

export default BackButton;
