import React, { useContext, useEffect } from 'react';

import calculatePercentage from 'utilis/calculatePercentage';
import TournamentContext from 'context/TournamentContext';
import useFetch from 'hooks/useFetch';
import { useNavigate } from 'react-router-dom';
import { LEAVE_TOURNAMENT_URL } from 'constants/apiRoutes';
import AuthContext from 'context/AuthContext';
import { LoadingButton } from '@mui/lab';
import CircularWithValueLabel from 'components/Progress/CircularWithValueLabel';
import { Box } from '@mui/material';

function InfoSection() {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;
  const { user } = useContext(AuthContext);
  const { owner } = tournament;

  const isOwner = user.id === owner.id;

  const navigate = useNavigate();
  const { fetchData, isLoading: isExiting } = useFetch(`${LEAVE_TOURNAMENT_URL}/${tournament?.uniqueId}`, {
    fetchOnMount: false,
    method: 'PATCH',
    onSuccess: () => {
      navigate('/user');
    },
  });

  const { fetchData: startTournamentRequest, isLoading: isStarting } = useFetch(`/api/tournament/start/${tournament?.uniqueId}`, {
    method: 'PATCH',
    fetchOnMount: false,
  });

  useEffect(() => {
    const progressBar = document.querySelector('#progress-value');

    if (progressBar) {
      const percentage = calculatePercentage(players.length, tournament.numberOfPlayers);
      progressBar.style.width = `${percentage}%`;
    }
  }, [players.length]);

  const leaveTournament = () => {
    fetchData();
  };

  const startTournament = () => {
    startTournamentRequest();
  };

  return (
    <>
      <Box>
        <CircularWithValueLabel
          label={
          `${players.length}
          /
          ${tournament.numberOfPlayers}`
          }
          progress={
            (players.length / tournament.numberOfPlayers) * 100
          }
        />
      </Box>
      <Box alignSelf="normal">
        {(players.length >= 2 && isOwner)
        && (
        <LoadingButton
          type="button"
          variant="contained"
          fullWidth
          onClick={startTournament}
          loading={isStarting}
        >
          Стартирай турнира
        </LoadingButton>
        )}
        <LoadingButton
          onClick={leaveTournament}
          type="button"
          variant="contained"
          fullWidth
          loading={isExiting}
          sx={{ mt: 3 }}
        >
          Откажи
        </LoadingButton>
      </Box>
    </>
  );
}
export default InfoSection;
