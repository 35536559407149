import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const topics = [
  {
    name: 'General Knowledge',
    id: 9,
  },
  {
    name: 'Science & Nature',
    id: 17,
  },
  {
    name: 'Science: Computers',
    id: 18,
  },
  {
    name: 'Science: Mathematics',
    id: 19,
  },
  {
    name: 'Mythology',
    id: 20,
  },
  {
    name: 'Sports',
    id: 21,
  },
  {
    name: 'Geography',
    id: 22,
  },
  {
    name: 'History',
    id: 23,
  },
  {
    name: 'Animals',
    id: 27,
  },
  {
    name: 'Vehicles',
    id: 28,
  },
  {
    name: 'Science: Gadgets',
    id: 30,
  },
];

// this hook is purley experimental
// it automaticly fetches topic and question and uses them for the game
// read for_me.md before removing it
const removeSymbolsFromString = (string) => {
  const entityRegex = /&[^\s;]+;/g;

  // Remove HTML entities from the string
  return string.replace(entityRegex, '');
};
const shuffle = (array) => {
  let currentIndex = array.length; let
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // eslint-disable-next-line no-param-reassign
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};

const useIsAuto = ({
  chooseTopicFunction = null, choseQuestionFunction = null, tiebreak = false, tournament,
}) => {
  const { auto } = useParams();

  const [isAuto, setIsAuto] = useState(false);
  const isFetching = useRef(false);

  useEffect(() => {
    if (auto === 'auto') {
      setIsAuto(true);
    }
  }, [auto]);

  useEffect(() => {
    if (isAuto && isFetching.current === false && chooseTopicFunction) {
      isFetching.current = true;

      const numTopics = topics.length;
      const randomTopicIndex = Math.floor(Math.random() * numTopics);
      const topic = topics[randomTopicIndex];
      chooseTopicFunction({
        questionTopic: `${topic.name} ${topic.id}`,
      });
    }
  }, [isAuto]);

  useEffect(() => {
    if (isAuto && isFetching.current === false && choseQuestionFunction) {
      isFetching.current = true;
      const topicSplited = tournament.handState.questionTopic.split(' ');
      const topicId = topicSplited[topicSplited.length - 1];

      axios.get(`https://opentdb.com/api.php?amount=1&category=${topicId}`)
        .then((res) => {
          const { question, correct_answer } = res.data.results[0];
          const cleanQuestion = removeSymbolsFromString(question);
          const options = shuffle([correct_answer, ...res.data.results[0].incorrect_answers]);
          localStorage.setItem('correct_answer', correct_answer);
          let optionsText = '(';

          options.forEach((option, index) => {
            optionsText += `${removeSymbolsFromString(option)},`;
          });

          optionsText = `${optionsText.slice(0, -1)})`;

          const questionText = `${cleanQuestion} ${optionsText}`;
          if (tiebreak) {
            choseQuestionFunction({
              tiebreakQuestion: questionText,
            });
          } else {
            choseQuestionFunction({
              question: questionText,
            });
          }
        });
    }
  }, [isAuto]);

  return isAuto;
};

export default useIsAuto;
