import React from 'react';
import TextInputModal from 'components/Modals/TextInputModal';
import Timer from 'components/Timer/Timer';
import useAnswerControls from 'hooks/useAnswerControls';

function EnterAnswer() {
  const {
    showModal, timeLeft, closeModal,
  } = useAnswerControls();

  return (
    <>
      {showModal
      && (
      <TextInputModal
        allowEmpty
        headerText="Напиши отговор"
        onClose={closeModal}
        minTextLength={1}
        preventClose
      >

        <Timer
          initialTime={timeLeft}
          maxTime={timeLeft}
          options={{ top: true }}
          onTimeOut={closeModal}
        />

      </TextInputModal>
      )}
    </>
  );
}

export default EnterAnswer;
