import { JOIN_TOURNAMENT_URL } from 'constants/apiRoutes';
import useFetch from 'hooks/useFetch';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import useIsMobile from 'hooks/useIsMobile';

function JoinTournamentButton({ tournamentId, setIsEntering, hasTournamentStarted }) {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const {
    isLoading, fetchData,
  } = useFetch(`${JOIN_TOURNAMENT_URL}/${tournamentId}`, {
    method: 'PATCH',
    fetchOnMount: false,
    onSuccess: () => {
      navigate(`/tournament/${tournamentId}`);
    },
    onError: (data) => {
      let message = '';
      if (data?.response?.data?.message) {
        message = data?.response?.data.message;
      } else {
        message = 'Нещо се обърка';
      }
      toast.error(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  const enterTournament = () => {
    if (hasTournamentStarted) {
      navigate(`/tournament/${tournamentId}`);
    } else {
      fetchData();
      setIsEntering(true);
    }
  };

  return (
    <LoadingButton
      onClick={enterTournament}
      type="button"
      variant="contained"
      color="primary"
      loading={isLoading}
      // size={isMobile ? 'small' : 'medium'}
      sx={{
        p: 0,
        m: 0,
        boxShadow: 'none',
      }}
    >
      Влез
    </LoadingButton>
  );
}

export default JoinTournamentButton;
