import { useContext, useEffect, useState } from 'react';
import TournamentContext from 'context/TournamentContext';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import getCallValue from 'utilis/getCallValue';

const useBetting = () => {
  const { tournament } = useContext(TournamentContext);
  const { stateId } = tournament.handState;
  const { players } = tournament;

  const [callValue, setCallValue] = useState(0);
  const [shouldBlockAllInButton, setShouldBlockAllInButton] = useState(false);
  const [raise, setRaise] = useState({
    value: 0,
    min: 0,
    max: 0,
    isPossible: true,
  });

  const setMinRaise = (newValue) => {
    setRaise((prevRaise) => ({
      ...prevRaise,
      min: newValue,
      value: newValue,
    }));
  };

  useEffect(() => {
    // Calculate initial raise values and call value
    const { highestBet, currentPlayer } = getCurrentPlayerAndHighestBet(players);
    const { currentLevel } = tournament.tournamentState;

    const [smallBlindString, bigBlindString] = currentLevel.split('/');

    const bigBlind = Number(bigBlindString);
    const thereIsBetOnTable = highestBet > 0;

    setRaise((prevRaise) => ({
      ...prevRaise,
      max: currentPlayer.currentStack + currentPlayer.currentBet,
    }));

    setCallValue(
      getCallValue(
        highestBet,
        currentPlayer.currentBet,
        currentPlayer.currentStack,
        bigBlind,
      ),
    );

    if (stateId === TOURNAMENT_STATES.FIRST_BETTING) {
      if (thereIsBetOnTable) {
        // Means the heighest bet could not pay what he must and went ALL-IN so don't consider him
        if (highestBet <= bigBlind) {
          setMinRaise(bigBlind * 2);
          return;
        }
      }
    }

    if (stateId === TOURNAMENT_STATES.SECOND_BETTING) {
      if (thereIsBetOnTable === false) {
        // If no bet yet and the player doesn't have enough money, let them go all in
        if (bigBlind > currentPlayer.currentStack) {
          setMinRaise(currentPlayer.currentStack);
          return;
        }

        // If no bet yet and player has enough money, min raise is BB
        setMinRaise(bigBlind);
        return;
      }

      // If someone has betted but the bet is not greater than BB, set min raise to BB * 2
      if (highestBet < bigBlind) {
        setMinRaise(bigBlind * 2);
        return;
      }
    }

    // Someone has raised
    // Take the last raise size + current highest bet as the min raise
    const lastRaiser = players.find((player) => player.isLastRaiser);
    const lastRaiseSize = lastRaiser.raiseSize;

    setMinRaise(highestBet + lastRaiseSize);
  }, [players]);

  useEffect(() => {
    const { currentPlayer } = getCurrentPlayerAndHighestBet(players);
    const isMinGreaterThanMax = raise.min > raise.max;
    const newRaise = currentPlayer.currentStack + currentPlayer.currentBet;

    if (isMinGreaterThanMax) {
      setRaise((prevRaise) => ({
        ...prevRaise,
        value: newRaise,
        isPossible: false,
      }));
    } else {
      setRaise((prevRaise) => ({
        ...prevRaise,
        isPossible: true,
      }));
    }
  }, [raise.value, raise.min, raise.max]);

  useEffect(() => {
    // block all in button if the call value is going to leave the player with no money
    const { currentPlayer } = getCurrentPlayerAndHighestBet(players);
    const leavesWithNoMoney = currentPlayer.currentStack - callValue === 0;

    setShouldBlockAllInButton(leavesWithNoMoney === true);
  }, [callValue, raise.value]);

  return {
    raise,
    callValue,
    shouldBlockAllInButton,
    setRaise,
  };
};

export default useBetting;
