import React from 'react';
import topics from 'constants/topics';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

function TopicsSelect({ updateText }) {
  const changeTopic = (e) => {
    const topicInput = document.getElementById('text_input_modal_value');
    if (topicInput) {
      topicInput.value = e.target.value;
      updateText(e);
    }
  };
  return (
    <FormControl fullWidth sx={{ mb: 3 }}>
      <InputLabel id="topic-select">Тема</InputLabel>
      <Select
        labelId="topic-select"
        id="topic-select"
        label="Друга тема"
        name="topic"
        onChange={changeTopic}
        defaultValue=""
      >
        {
            topics.map((topic) => (
              <MenuItem key={topic} value={topic}>{topic}</MenuItem>
            ))
          }
        <MenuItem value="">Друга тема</MenuItem>
      </Select>
    </FormControl>
  );
}

export default TopicsSelect;
