import {
  FormControl, RadioGroup, Radio, FormControlLabel, Stack, Typography, TextField,
} from '@mui/material';
import React, { useRef } from 'react';

function LoadedQuestionsForm({
  onPick, questions,
}) {
  const questionRefs = useRef([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const searchByNumber = (e) => {
    const { value } = e.target;
    setSearchTerm('');
    if (value === '') {
      return;
    }

    const questionToScrollTo = questions.find((question) => question.number.toString() === value);
    if (questionToScrollTo) {
      const questionRef = questionRefs.current[questionToScrollTo.number];
      if (questionRef && questionRef.scrollIntoView) {
        questionRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const searchByText = (e) => {
    const { value } = e.target;
    setSearchTerm(value.toLowerCase());
    if (value === '') {
      return;
    }

    const searchText = value.toLowerCase();
    const questionToScrollTo = questions.find(
      (question) => question.text.toLowerCase().includes(searchText),
    );
    if (questionToScrollTo) {
      const questionRef = questionRefs.current[questionToScrollTo.number];
      if (questionRef && questionRef.scrollIntoView) {
        questionRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2}>
        <TextField
          fullWidth
          label="Търси по номер"
          onChange={searchByNumber}
        />
        <TextField
          fullWidth
          label="Търси по текст"
          onChange={searchByText}
        />
      </Stack>
      <Stack sx={{
        maxHeight: '15em', overflowY: 'auto', border: '1px solid grey', borderRadius: '12px',
      }}
      >
        <FormControl>
          <RadioGroup name="questions">
            <Stack spacing={2} py={2}>
              {questions.map((question, index) => (
                <div
                  // eslint-disable-next-line no-return-assign
                  ref={(el) => questionRefs.current[index] = el}
                  key={question.number}
                >
                  <FormControlLabel
                    value={question.text}
                    control={(
                      <Radio
                        disable={question.asked}
                        onChange={() => {
                          onPick(question);
                        }}
                      />
)}
                    label={(
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Typography fontWeight={600}>
                          {question.number}
                          .
                        </Typography>
                        <Typography
                          sx={{
                            textDecoration: question.asked ? 'line-through' : 'auto',
                            color: question.asked ? 'red' : 'black',
                          }}
                        >
                          <HighlightText
                            text={question.text}
                            searchTerm={searchTerm}
                          />
                        </Typography>
                      </Stack>
                    )}
                    sx={{
                      px: 2,
                      width: '95%',
                      overflowWrap: 'break-word',
                      '& .MuiFormControlLabel-label': {
                        width: '90%',
                      },
                    }}
                  />
                </div>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
}

function HighlightText({ text, searchTerm }) {
  if (!searchTerm) {
    return text;
  }

  const searchText = searchTerm.toLowerCase();
  const lowerCaseText = text.toLowerCase();
  const words = lowerCaseText.split(searchText);
  let lastIndex = 0;

  return (
    <>
      {words.map((word, index) => {
        const caseAdjustedWord = text.substr(lastIndex, word.length);
        lastIndex += word.length + searchTerm.length;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {caseAdjustedWord}
            {index !== words.length - 1 && <span style={{ backgroundColor: 'yellow' }}>{searchTerm}</span>}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default LoadedQuestionsForm;
