import React, { useContext } from 'react';
import TournamentContext from 'context/TournamentContext';
import {
  currentLevelText, numPlayersText, remainingQuestionsText, tournamentStateText,
} from 'constants/text/dashboard';
import {
  Box, Grid, Typography, useTheme,
} from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function TournamentStateTable() {
  const { tournament } = useContext(TournamentContext);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const textStyles = {
    textAlign: 'center',
    fontSize: { md: '16px', xs: '10px' },
    lineHeight: 0.9,
  };
  return (
    <Box mt={isMobile ? 0 : 2}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: theme.palette.primary.main,
            color: 'white',
            p: isMobile ? '4px' : 1,
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
        >
          <Grid item xs={4}>
            <Typography sx={textStyles}>{numPlayersText}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={textStyles}>{currentLevelText}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={textStyles}>{remainingQuestionsText}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            p: isMobile ? '4px' : 1,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <Grid item xs={4} data-testid="remaining_players">
            <Typography sx={textStyles}>{tournament.tournamentState.remainingPlayers}</Typography>
          </Grid>
          <Grid item xs={4} data-testid="current_level">
            <Typography sx={textStyles}>{tournament.tournamentState.currentLevel}</Typography>
          </Grid>
          <Grid item xs={4} data-testid="remaining_questions">
            <Typography sx={textStyles}>
              {tournament.tournamentState.remainingQuestionsBeforeNextLevel}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TournamentStateTable;
