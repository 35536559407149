export const numPlayersText = 'Брой играчи';
export const currentLevelText = 'Текущо ниво';
export const remainingQuestionsText = 'Оставащи въпроси';
export const tournamentStateText = 'Състояние на турнира';
export const historyLogsHeadingText = 'История на активността';
export const minButtonText = 'Мин';
export const maxButtonText = 'Макс';
export const halfRaiseText = '1/2';
export const potRaiseText = 'Пот';
export const allInButtonText = 'Алл ин';
export const foldButtonText = 'Откажи се';
export const callButtonText = 'Плати';
export const raiseButtonText = 'Покачи до';
export const betButtonText = 'Заложи';
export const checkButtonText = 'Пасувай';
export const announceTopicButtonText = 'Обяви темата';
export const announceQuestionButtonText = 'Обяви въпроса';
export const announceWinnersButtonText = 'Обяви победителите';
export const chooseWinnersHeadingText = 'Избери победителите';
export const confirmWinnersButtonText = 'Потвърди';
