import { useState } from 'react';

const useUploadedQuestions = () => {
  const [questions, setQuestions] = useState([]);

  const extractQuestionsFromText = (text) => {
    setQuestions(text.split('\n'));

    const separatedQuestions = text.split('\n');

    const tempQuestions = [];
    let questionNumber = 1;
    separatedQuestions.forEach((question) => {
      if (question.trim() !== '') {
        tempQuestions.push({
          text: question,
          asked: false,
          number: questionNumber,
        });
        questionNumber += 1;
      }
    });

    setQuestions(tempQuestions);
  };

  const markQuestionAsAsked = (questionNumber) => {
    setQuestions((prev) => prev.map((question) => {
      if (question.number === questionNumber) {
        return {
          ...question,
          asked: true,
        };
      }
      return question;
    }));
  };
  return {
    questions,
    setQuestions,
    markQuestionAsAsked,
    extractQuestionsFromText,
  };
};

export default useUploadedQuestions;
