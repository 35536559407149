import { Box, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import getTimeLeftToEndTime from 'utilis/getTimeLeftToEndTime';

function GlobalTimer({ endTime }) {
  const timezone = 'Europe/Sofia';

  const [timeLeft, setTimeLet] = useState(getTimeLeftToEndTime(endTime, timezone));

  useEffect(() => {
    setInterval(() => {
      setTimeLet(getTimeLeftToEndTime(endTime, timezone));
    }, 300);
  }, []);

  return (
    <Box>
      ⏰
      {' '}
      {timeLeft}
      {' '}
      минути
    </Box>
  );
}

export default GlobalTimer;
