const applyBetAnimation = (playerBetRef) => {
  const betAnimationDuration = 500;

  if (playerBetRef.current) {
    playerBetRef.current.classList.add('animation_bet');

    setTimeout(() => {
      if (playerBetRef.current) {
        playerBetRef.current.classList.remove('animation_bet');
      }
    }, betAnimationDuration);
  }
};

export default applyBetAnimation;
