import React from 'react';

function RotateDevice() {
  return (
    <div className="rotate_device_body">
      <div className="phone" />
      <div className="message">
        Моля завъртете устройството!
      </div>
    </div>
  );
}

export default RotateDevice;
