import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import GAME_MOVES from 'constants/gameMoves';
import { checkButtonText } from 'constants/text/dashboard';
import { LoadingButton } from '@mui/lab';
import useIsMobile from 'hooks/useIsMobile';

function CheckButton({ buttonMobileStyles }) {
  const isMobile = useIsMobile();
  const {
    isRequestResolved,
    handleMove,
  } = useContext(BettingContext);

  return (
    <LoadingButton
      onClick={() => handleMove({ move: GAME_MOVES.CHECK })}
      loading={isRequestResolved === false}
      id="pass_button"
      type="button"
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}
    >
      {checkButtonText}
    </LoadingButton>
  );
}

export default CheckButton;
