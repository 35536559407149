import { useEffect, useState, useContext } from 'react';
import AuthContext from 'context/AuthContext';
import TournamentContext from 'context/TournamentContext';
import { PLAYER_URL } from 'constants/apiRoutes';
import useFetch from 'hooks/useFetch';

const useAnswerControls = () => {
  const { user } = useContext(AuthContext);
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;

  const [showModal, setShowModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState();

  const myPlayer = players.find((player) => player.userId === user.id);

  const { fetchData } = useFetch(`${PLAYER_URL}/${tournament.uniqueId}`, {
    method: 'PATCH',
    fetchOnMount: false,
  });

  const closeModal = (text) => {
    // if the function is called by onTimeOut from timer there will be no text value
    const input = document.getElementById('text_input_modal_value');
    setShowModal(false);
    fetchData({
      answer: text || input.value,
      id: myPlayer.id,
    });
  };

  useEffect(() => {
    if (myPlayer.answer !== null) {
      return;
    }

    if (myPlayer.secondsLeft <= 0) {
      return;
    }

    setShowModal(true);
    setTimeLeft(myPlayer.secondsLeft);
  }, [myPlayer.secondsLeft, myPlayer.answer]);

  return {
    showModal,
    timeLeft,
    closeModal,
  };
};

export default useAnswerControls;
