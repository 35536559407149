/* eslint-disable consistent-return */
import React from 'react';
import Loading from 'components/Loading/Loading';
import Table from 'components/Table/Table';
import Dashboard from 'components/Dashboard/Dashboard';
import useTournament from 'hooks/useTournament';
import TournamentContext from 'context/TournamentContext';
import useProtected from 'hooks/useProtected';
import TOURNAMENT_STATUS from 'constants/tournamentStatus';
import GamePending from 'components/GamePending/GamePending';
import useGlobalTimer from 'hooks/useGlobalTimer';
import { CircularProgress, Box, useMediaQuery } from '@mui/material';
import RotateDevice from 'components/RotateDevice/RotateDevice';
import useUploadedQuestions from 'hooks/useUploadedQuestions';
import UploadedQuestionsContext from 'context/UploadedQuestionsContext';

function TournamentRoom() {
  const { isLoading, tournament, isGameStarting } = useTournament();
  const { isLoading: isStillFetching } = useProtected({ redirectUrl: '/', hasToBeLogged: true });
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const {
    questions,
    extractQuestionsFromText,
    markQuestionAsAsked,
  } = useUploadedQuestions();

  useGlobalTimer({
    isFetching: isStillFetching,
    isLoading,
    tournament,
  });

  if (isPortrait) {
    return <RotateDevice />;
  }
  if (isLoading || isStillFetching) {
    return <Loading />;
  }

  return (
    <TournamentContext.Provider value={{ tournament }}>
      <UploadedQuestionsContext.Provider
        value={{
          questions,
          extractQuestionsFromText,
          markQuestionAsAsked,
        }}
      >
        <div className="game_room_holder" data-testid="game_holder">
          <Table />
          {
          isGameStarting
          && (
          <Box width="30%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
          )
        }
          {
          (tournament.tournamentStatusId === TOURNAMENT_STATUS.PENDING && !isGameStarting)
           && <GamePending />
        }
          {
          (tournament.tournamentStatusId === TOURNAMENT_STATUS.IN_GAME && !isGameStarting)
           && <Dashboard />
        }
        </div>
      </UploadedQuestionsContext.Provider>
    </TournamentContext.Provider>
  );
}

export default TournamentRoom;
