export const tournamentFormHeaderText = 'Въведете данните на играта';
export const tournamentFormatLabelText = 'Формат на турнира';
export const startingLevelLabelText = 'Начално ниво';
export const numPlayerLabelText = 'Максимален брой играчи';
export const numQuestionsLabelText = 'Брой въпроси на ниво';
export const coinsAtStartLabelText = 'Начални жетони';
export const anteCheckboxLabelText = 'Анте';
export const anteSizeLabelText = 'Размер на анте';
export const continueButtonText = 'Продължаване';
export const lifeTimeLabelText = 'Времетраене в минути';

export const playersFormHeaderText = 'Въведете имената на играчите';
export const playerLabelText = 'Играч';
export const submitButtonText = 'Продължаване';
export const playerInputPlaceHolderText = 'Име на играч';
export const goBackButtonText = 'Назад';
