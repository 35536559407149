import React, { useContext } from 'react';
import GAME_MOVES from 'constants/gameMoves';
import { raiseButtonText, allInButtonText } from 'constants/text/dashboard';
import BettingContext from 'context/BettingContext';
import TournamentContext from 'context/TournamentContext';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';
import { LoadingButton } from '@mui/lab';
import useIsMobile from 'hooks/useIsMobile';

function RaiseButton({ buttonMobileStyles }) {
  const isMobile = useIsMobile();
  const {
    raise,
    isRequestResolved,
    shouldBlockAllInButton,
    callValue,
    handleMove,
  } = useContext(BettingContext);

  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;

  // if all players are all in dont allow raise
  let areAllOtherAllIn = true;
  players.forEach((player) => {
    if (!player.isCurrent) {
      if (player.currentStack > 0) {
        areAllOtherAllIn = false;
      }
    }
  });

  const { currentPlayer } = getCurrentPlayerAndHighestBet(players);

  const lastRaiser = players.find((player) => player.isLastRaiser);
  const { currentLevel } = tournament.tournamentState;

  const [smallBlindString, bigBlindString] = currentLevel.split('/');
  // if someone went all in with less than min raise block the raise button
  // for the players which acted before him
  let block = false;
  if (lastRaiser.currentStack === 0) {
    if (currentPlayer.currentBet * 1.5 > lastRaiser.currentBet
      && lastRaiser.currentBet > Number(bigBlindString)) {
      block = true;
    }
  }

  const isButtonDisabled = shouldBlockAllInButton === true
  || raise.max === callValue
  || areAllOtherAllIn === true
  || block === true;

  const makeMove = () => {
    handleMove({
      move: GAME_MOVES.RAISE,
      raiseValue: Math.floor(raise.value),
    });
  };

  return (
    <LoadingButton
      type="button"
      onClick={makeMove}
      disabled={isButtonDisabled}
      id="raise_button"
      fullWidth
      loading={isRequestResolved === false}
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}
    >
      {raise.isPossible === true ? raiseButtonText : allInButtonText}
      {' '}
      {isButtonDisabled === false ? `(${Math.floor(raise.value)})` : null}
    </LoadingButton>
  );
}

export default RaiseButton;
