import { Typography } from '@mui/material';
import TournamentContext from 'context/TournamentContext';
import React, { useContext } from 'react';

function Owner() {
  const { tournament } = useContext(TournamentContext);
  const { owner } = tournament;

  const textStyles = {
    textAlign: 'center',
    fontSize: { md: '26px', xs: '11px' },
  };

  return (
    <>
      <Typography sx={textStyles}>Собственик</Typography>
      <img
        src={`/images/avatars/avatar-${owner?.avatarId}.png`}
        alt="owner"
        style={{
          width: '40%',
        }}
      />
      <Typography sx={textStyles}>{owner.username}</Typography>
    </>
  );
}

export default Owner;
