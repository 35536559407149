import React from 'react';

function ImageSelect({ values, avatarId, updateAvatarImage }) {
  return (
    <div className="image_select_holder">
      {
            values.map((value) => (
              <div
                className={avatarId === value.id ? 'selected' : null}
                onClick={() => { updateAvatarImage(value.id); }}
                type="button"
                key={value.id}
                data-testid="avatar"
              >
                <img src={value.url} alt="avatar" />
              </div>
            ))
        }
    </div>
  );
}

export default ImageSelect;
