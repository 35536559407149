const API_URL_PREFIX = '/api';

export const USER_URL = `${API_URL_PREFIX}/user`;
export const LOGIN_URL = `${API_URL_PREFIX}/session/auth`;
export const PLAYERS_URL = `${API_URL_PREFIX}/players`;
export const TOURNAMENT_URL = `${API_URL_PREFIX}/tournament`;
export const TOURNAMENTS_URL = `${API_URL_PREFIX}/tournaments`;
export const HAND_STATE_URL = `${API_URL_PREFIX}/handState`;
export const HISTORY_LOGS_URL = `${API_URL_PREFIX}/historyLogs`;
export const WINNERS_URL = `${API_URL_PREFIX}/winners`;
export const JOIN_TOURNAMENT_URL = `${API_URL_PREFIX}/join`;
export const LEAVE_TOURNAMENT_URL = `${API_URL_PREFIX}/leave`;
export const GET_COOKIE_URL = '/sanctum/csrf-cookie';
export const PLAYER_URL = `${API_URL_PREFIX}/player`;
export const PLAYERS_ANSWERS_URL = `${API_URL_PREFIX}/players/answers`;
export const ASKED_QUESTIONS_URL = `${API_URL_PREFIX}/askedQuestions`;
export const UNASKED_QUESTIONs_URL = `${API_URL_PREFIX}/unaskedChosenQuestions`;
