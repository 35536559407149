import React from 'react';
import { useNavigate } from 'react-router-dom';

function NotFoundPage() {
  const navigate = useNavigate();
  const redirectToMainPage = () => {
    navigate('/');
  };
  return (
    <div className="not_found_page">
      <div>404</div>
      <p>Май си се изгубил</p>
      <button onClick={redirectToMainPage} type="button">Върни се към началаната страница</button>
    </div>
  );
}

export default NotFoundPage;
