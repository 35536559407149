import { useState } from 'react';

const useWinners = () => {
  const [winners, setWinners] = useState([]);
  const [rightAnswer, setRightAnswer] = useState('');

  const handleCheckboxChange = (e) => {
    const { checked, name: id } = e.target;
    if (checked) {
      setWinners((prevState) => [...prevState, id]);
    } else {
      setWinners((prevState) => prevState.filter((playerId) => playerId !== id));
    }
  };

  const updateRightAnswer = (e) => {
    setRightAnswer(e.target.value);
  };

  return {
    winners,
    rightAnswer,
    handleCheckboxChange,
    updateRightAnswer,
  };
};

export default useWinners;
