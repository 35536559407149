import React from 'react';
import { TOURNAMENT_STATE_VALUES, STARTING_LEVEL_VALUES } from 'constants/tournamentDataConstants';
import FormImageWrapper from 'components/Forms/FormImageWrapper';
import BackButton from 'components/Buttons/BackButton';
import {
  anteSizeLabelText,
  coinsAtStartLabelText,
  continueButtonText,
  numPlayerLabelText,
  numQuestionsLabelText,
  startingLevelLabelText,
  tournamentFormHeaderText,
  tournamentFormatLabelText,
  lifeTimeLabelText,
} from 'constants/text/createTournament';
import {
  InputLabel, Stack, Typography, FormControl, Select, MenuItem, TextField, Button, Box,
} from '@mui/material';

function TournamentStatsForm({ tournamentData, updateTournamentData, onSubmit }) {
  return (
    <FormImageWrapper>
      <Box
        sx={{ height: '100vh' }}
      >
        <form
          className="setup_game_form"
          onChange={updateTournamentData}
          onSubmit={onSubmit}
          data-testid="tournament_stats_form"
          style={{ padding: '2em', maxHeight: '100%', overflowY: 'auto' }}
        >

          <BackButton backUrl="/user" />
          <Stack spacing={2}>
            <Typography variant="h5" textAlign="center">{tournamentFormHeaderText}</Typography>
            <FormControl fullWidth>
              <InputLabel id="tournamentFormat">{tournamentFormatLabelText}</InputLabel>
              <Select
                labelId="tournamentFormat"
                defaultValue={TOURNAMENT_STATE_VALUES[0].value}
                name="tournamentFormat"
                label={tournamentFormatLabelText}
                onChange={updateTournamentData}
              >
                {
                TOURNAMENT_STATE_VALUES.map((tournamentState) => (
                  <MenuItem
                    value={tournamentState.value}
                    key={tournamentState.value}
                  >
                    {tournamentState.name}
                  </MenuItem>
                ))
              }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="startingLevel">{startingLevelLabelText}</InputLabel>
              <Select
                labelId="startingLevel"
                defaultValue={STARTING_LEVEL_VALUES[0].value}
                name="startingLevel"
                label={startingLevelLabelText}
                onChange={updateTournamentData}
              >
                {
                STARTING_LEVEL_VALUES.map((startingLevel) => (
                  <MenuItem
                    value={startingLevel.value}
                    key={startingLevel.value}
                  >
                    {startingLevel.name}
                  </MenuItem>
                ))
              }
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label={numPlayerLabelText}
              variant="outlined"
              defaultValue={tournamentData.numPlayers}
              name="numPlayers"
              required
              type="number"
              step={1}
              inputProps={{
                inputMode: 'numeric', pattern: '[0-9]*', min: 2, max: 9,
              }}
            />

            <TextField
              fullWidth
              label={numQuestionsLabelText}
              variant="outlined"
              defaultValue={tournamentData.questionsPerLevel}
              name="questionsPerLevel"
              required
              type="number"
              step={1}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1 }}
            />

            <TextField
              fullWidth
              label={coinsAtStartLabelText}
              variant="outlined"
              defaultValue={tournamentData.coinsAtStart}
              name="coinsAtStart"
              required
              type="number"
              step={1}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 100 }}
            />
            <TextField
              fullWidth
              label={lifeTimeLabelText}
              variant="outlined"
              defaultValue={tournamentData.lifeTime}
              name="lifeTime"
              required
              type="number"
              step={1}
              inputProps={{
                inputMode: 'numeric', pattern: '[0-9]*', min: 5, max: 240,
              }}
            />
            <TextField
              fullWidth
              label="Брой стандартни въпроси"
              variant="outlined"
              defaultValue={tournamentData.numStandartQuestions}
              name="numStandartQuestions"
              required
              type="number"
              step={1}
              inputProps={{
                inputMode: 'numeric', pattern: '[0-9]*', min: 10, max: 100,
              }}
            />
            <TextField
              fullWidth
              label="Брой тайбрейк въпроси"
              variant="outlined"
              defaultValue={tournamentData.numTiebrakeQeustions}
              name="numTiebrakeQeustions"
              required
              type="number"
              step={1}
              inputProps={{
                inputMode: 'numeric', pattern: '[0-9]*', min: 10, max: 100,
              }}
            />
            <div className="checkbox_holder">
              <label htmlFor="cbtest-19">Анте</label>
              <div className="checkbox-wrapper-19">
                <input id="cbtest-19" type="checkbox" name="hasAnte" />
                <label htmlFor="cbtest-19" className="check-box" />
              </div>
            </div>

            <Button variant="contained" type="submit">{continueButtonText}</Button>
          </Stack>
        </form>
      </Box>

    </FormImageWrapper>
  );
}

export default TournamentStatsForm;
