import {
  Stack, Typography, Grid, useTheme,
} from '@mui/material';
import React from 'react';

function TournamentStatsHeader() {
  const theme = useTheme();

  const textStyles = {
    fontSize: {
      md: '18px',
      xs: '11px',
    },
    textAlign: 'center',
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="left"
      sx={{
        bgcolor: theme.palette.primary.main,
        color: 'white',
        py: 1,
        px: 2,
        borderTopLeftRadius: { md: '6px', xs: null },
        borderTopRightRadius: { md: '6px', xs: null },
        mt: 5,
      }}
    >
      <Grid item xs={3}>
        <Typography sx={textStyles}>Директор</Typography>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography sx={textStyles}>
            Вид
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography sx={textStyles}>
            Залог
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography sx={textStyles}>
            Играчи
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}

export default TournamentStatsHeader;
