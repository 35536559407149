import {
  Grid, Typography, Divider, Stack, Chip, Box,
} from '@mui/material';
import JoinTournamentButton from 'components/Buttons/JoinTournamentButton';
import TOURNAMENT_STATUS from 'constants/tournamentStatus';
import React, { useState } from 'react';
import getTournamentType from 'utilis/getTournamentType';
import CircularWithValueLabel from 'components/Progress/CircularWithValueLabel';

function TournamentListItem({ tournament }) {
  const [isEntering, setIsEntering] = useState(false);
  const textStyles = {
    fontSize: {
      md: '16px',
      xs: '11px',
    },
    textAlign: 'center',
  };

  return (
    <React.Fragment key={tournament.uniqueId}>
      <Grid
        container
        alignItems="center"
        justifyContent="left"
        sx={{
          py: 1,
          px: 2,
        }}
      >
        <Grid item xs={3}>
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
            <img style={{ height: '40px' }} src={`/images/avatars/avatar-${tournament.owner?.avatarId}.png`} alt="avatar" />
            <Typography
              fontWeight={600}
              sx={{
                ...textStyles,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              textOverflow="ellipsis"
            >
              {tournament.owner.username}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Chip
            sx={{ width: '100%', fontWeight: '600' }}
            label={
                getTournamentType(tournament.tournamentFormatId)
              }
          />
        </Grid>
        <Grid item xs={2}>
          <Stack alignItems="center" justifyContent="center">
            <Chip
              label={tournament.startingLevel}
            />

          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularWithValueLabel
              size="2.2em"
              fontSize={{ md: '11px', xs: '8px' }}
              label={
                      `${tournament?.players?.length || 0}
                      /
                      ${tournament.numberOfPlayers}`
                      }
              progress={
                            ((tournament?.players?.length || 0) / tournament.numberOfPlayers) * 100
                      }
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Stack justifyContent="end" direction="row">
            <JoinTournamentButton
              tournamentId={tournament.uniqueId}
              isEntering={isEntering}
              setIsEntering={setIsEntering}
              hasTournamentStarted={tournament.tournamentStatusId === TOURNAMENT_STATUS.IN_GAME}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
}

export default TournamentListItem;
