import { useContext, useEffect, useState } from 'react';
import TournamentContext from 'context/TournamentContext';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';

const usePotAndHalfRaise = () => {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;
  const { stateId } = tournament.handState;

  const [potRaise, setPotRaise] = useState({
    value: null,
    isPossible: false,
  });

  const [halfRaise, setHalfRaise] = useState({
    value: null,
    isPossible: false,
  });

  useEffect(() => {
    if (stateId === TOURNAMENT_STATES.FIRST_BETTING) {
      // check if only the BB and SB are placed

      let numBets = 0;
      players.forEach((player) => {
        if (player.currentBet !== 0 && player.currentBet !== null) {
          numBets += 1;
        }
      });

      // if only BB and SB are placed you cant pot raise
      if (numBets <= 2) {
        setPotRaise((prev) => ({ ...prev, isPossible: false }));
        setHalfRaise((prev) => ({ ...prev, isPossible: false }));
      } else {
        setPotRaise((prev) => ({ ...prev, isPossible: true }));
        setHalfRaise((prev) => ({ ...prev, isPossible: true }));
      }
    }

    if (stateId === TOURNAMENT_STATES.SECOND_BETTING) {
      setPotRaise((prev) => ({ ...prev, isPossible: true }));
      setHalfRaise((prev) => ({ ...prev, isPossible: true }));

      // check if someone has betted
      let numBets = 0;
      players.forEach((player) => {
        if (player.currentBet !== 0 && player.currentBet !== null) {
          numBets += 1;
        }
      });

      // if someone has betted there would be at least 1 bet
      if (numBets === 0) {
        // if no one has betted yet pot raise = pot, 1/2 raise = pot /2
        const pot = tournament.pots.reduce((sum, pot) => sum + pot.potSize, 0);
        setPotRaise((prev) => ({ ...prev, value: pot }));
        setHalfRaise((prev) => ({ ...prev, value: pot / 2 }));
      }
    }

    // potRaise = all bets + pot + heighest bet
    const { highestBet, currentPlayer } = getCurrentPlayerAndHighestBet(players);
    let allBetsValue = 0;
    players.forEach((players) => {
      if (players.currentBet) {
        allBetsValue += players.currentBet;
      }
    });

    let allBetsAndPot = allBetsValue;
    if (tournament.pots) {
      const pot = tournament.pots.reduce((sum, pot) => sum + pot.potSize, 0);
      allBetsAndPot += pot;
    }

    const finalPotValue = allBetsAndPot + highestBet;

    setPotRaise((prev) => ({ ...prev, value: finalPotValue }));

    // 1/2 raise = (all bets + pot)/2 + highest bet
    const finalHalfRaise = (allBetsAndPot) / 2 + highestBet;

    setHalfRaise((prev) => ({ ...prev, value: finalHalfRaise }));

    // if player does not have enough money don't allow to chose 1/2 raise
    if (finalHalfRaise > currentPlayer.currentStack) {
      setHalfRaise((prev) => ({ ...prev, isPossible: false }));
    }

    // if player does not have enough money don't allow to chose pot raise
    if (finalPotValue > currentPlayer.currentStack) {
      setPotRaise((prev) => ({ ...prev, isPossible: false }));
    }
  }, [players]);

  return {
    potRaise,
    halfRaise,
  };
};

export default usePotAndHalfRaise;
