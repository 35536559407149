import {
  notEqualPasswordsErrorText,
  shortEmailErrorText, shortPasswordErrorText, shortUsernameErrorText, startWithLetterErrorText,
} from 'constants/text/registrationForm';
import { useContext, useState } from 'react';
import AuthContext from 'context/AuthContext';
import { GET_COOKIE_URL, USER_URL } from 'constants/apiRoutes';
import convertSnakeToCamelCase from 'utilis/snakeToCamelCase';
import useFetch from 'hooks/useFetch';
import errorToast from 'components/toasts/errorToast';

const useRegistration = () => {
  const { logIn } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    repeatedPassword: '',
    username: '',
    imageId: 1,
  });

  const [errorMessages, setErrorMessages] = useState(null);

  const { fetchData, isLoading } = useFetch(USER_URL, {
    method: 'POST',
    fetchOnMount: false,
    onError: (data) => {
      let message = '';
      if (data?.response?.data?.message) {
        message = data?.response?.data.message;
      } else {
        message = 'Нещо се обърка';
      }
      errorToast(message);
    },
    onSuccess: (data) => {
      const { user } = convertSnakeToCamelCase(data);
      logIn(user);
    },
  });

  const { fetchData: getCookie } = useFetch(GET_COOKIE_URL, {
    fetchOnMount: false,
    onSuccess: () => {
      fetchData({
        email: userData.email,
        password: userData.password,
        username: userData.username,
        password_confirmation: userData.repeatedPassword,
        avatar_id: userData.imageId,
      });
    },
  });
  const updateAvatarImage = (imageId) => {
    setUserData((prevData) => ({ ...prevData, imageId }));
  };

  const updateUserData = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateUserData = () => {
    const messages = {};

    const {
      username, email, password, repeatedPassword,
    } = userData;
    if (username.length < 3) {
      messages.username = shortUsernameErrorText;
    }

    const [emailName] = email.split('@');

    if (emailName.length < 3) {
      messages.email = shortEmailErrorText;
    }

    if (!/[a-zA-Z]/.test(emailName[0])) {
      messages.email = startWithLetterErrorText;
    }

    if (password.length < 8) {
      messages.password = shortPasswordErrorText;
    }

    if (repeatedPassword !== password) {
      messages.repeatedPassword = notEqualPasswordsErrorText;
    }
    setErrorMessages(messages);

    // If messages = {} means there is no error
    return Object.keys(messages).length === 0;
  };

  const submitRegistration = (e) => {
    e.preventDefault();

    const areValid = validateUserData();

    if (areValid) {
      getCookie();
    }
  };

  return {
    userData,
    isLoading,
    errorMessages,
    updateUserData,
    updateAvatarImage,
    submitRegistration,
  };
};

export default useRegistration;
