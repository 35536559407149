import React from 'react';

function PlayerBlint({ player }) {
  return (
    <>
      { (player.isBigBlind === true || player.isBigBlind === 1) && <div className="big_blind">BB</div>}
      { (player.isSmallBlind === true || player.isSmallBlind === 1) && <div className="small_blind">SB</div>}
    </>
  );
}

export default PlayerBlint;
