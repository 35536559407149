/* eslint-disable no-param-reassign */
const spawnParticle = (particleContainer, x, y) => {
  if (!particleContainer) {
    return;
  }
  const particle = document.createElement('img');
  particle.setAttribute('src', '/images/particle_coin.png');
  particle.style.transform = `scale(${1 + Math.random() * 1})`;
  particle.className = 'particle';
  particle.style.left = `${x}px`;
  particle.style.top = `${y}px`;
  particleContainer.appendChild(particle);

  const speedX = 1 + Math.random() * 2;
  const speedY = 1 + Math.random() * 5;

  const angle = Math.random() * Math.PI * 2;

  const intervalId = setInterval(() => {
    const vx = Math.cos(angle) * speedX;
    const vy = Math.sin(angle) * speedY;
    x += vx;
    y += vy;
    particle.style.left = `${x}px`;
    particle.style.top = `${y}px`;
  }, 16);

  setTimeout(() => {
    particle.remove();
    clearInterval(intervalId);
  }, 200);
};
export default spawnParticle;
