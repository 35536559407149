export const formHeaderText = 'Регистрирайте се';
export const emailLabelText = 'Имейл';
export const passwordLabelText = 'Парола';
export const repeatedPasswordLabelText = 'Повторете паролата';
export const usernameLabelText = 'Потребителско Име';
export const submitButtonText = 'Регистрация';
export const chooseAvatarLabelText = 'Изберете аватар';

export const shortUsernameErrorText = 'Името трябва да е поне 3 симвула.';
export const shortEmailErrorText = 'Имейлът трябва да е поне 3 симвула.';
export const shortPasswordErrorText = 'Паролата трябва да е поне 8 симвула.';
export const startWithLetterErrorText = 'Имейлът трябва да започва с буква.';
export const notEqualPasswordsErrorText = 'Паролите не съвпадат';
