import { toast } from 'react-toastify';

const errorToast = (message) => {
  toast.error(message || 'Изникна грешка', {
    position: 'bottom-left',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};

export default errorToast;
