import { useContext, useEffect, useState } from 'react';
import TournamentContext from 'context/TournamentContext';
import GAME_MOVES from 'constants/gameMoves';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import getCurrentPlayerAndHighestBet from 'utilis/getCurrentPlayerAndHighestBet';
import useFetch from 'hooks/useFetch';
import { PLAYERS_URL } from 'constants/apiRoutes';
import errorToast from 'components/toasts/errorToast';

const useControls = () => {
  const { tournament } = useContext(TournamentContext);
  const { players } = tournament;
  const { stateId } = tournament.handState;

  const [isRequestResolved, setIsRequestResolved] = useState(true);
  const [
    showCheckAndBetButtons,
    setShowCheckAndBetButtons,
  ] = useState(stateId === TOURNAMENT_STATES.SECOND_BETTING);

  const { fetchData } = useFetch(`${PLAYERS_URL}/${tournament.uniqueId}`, {
    method: 'PATCH',
    fetchOnMount: false,
    isProtected: true,
    onError: (error) => {
      let message = '';
      if (error?.response?.data?.message) {
        message = error.response.data.message;
      } else {
        message = 'Нещо се обърка';
      }
      errorToast(message);
    },
    onSettled: () => {
      setIsRequestResolved(true);
    },
  });

  useEffect(() => {
    // on page refresh, if there is a bet already on the table, means
    // someone already have beted, so don't show the bet and check buttons
    const { highestBet } = getCurrentPlayerAndHighestBet(players);
    if (highestBet > 0) {
      setShowCheckAndBetButtons(false);
    }
  }, [players]);

  const handleMove = ({
    move, betValue, raiseValue,
  }) => {
    const { currentPlayer } = getCurrentPlayerAndHighestBet(players);
    if (!currentPlayer) {
      return;
    }

    if (move === GAME_MOVES.BET) {
      setShowCheckAndBetButtons(false);
    }

    if (move !== GAME_MOVES.FOLD) {
      localStorage.setItem('lastPlayerInSecondBetting', currentPlayer.userId);
    }

    setIsRequestResolved(false);
    fetchData({
      player_id: currentPlayer.id,
      move,
      ...(betValue !== null && { bet_value: betValue }),
      ...(raiseValue !== null && { raise: raiseValue }),
    });
  };

  return {
    showCheckAndBetButtons,
    isRequestResolved,
    handleMove,
  };
};

export default useControls;
