import React from 'react';
import ConfetiParticles from 'components/Particles/ConfetiParticles';
import WinnerModal from 'components/Modals/WinnerModal';

function WinnerPage() {
  return (
    <div>
      <ConfetiParticles />
      <WinnerModal />
    </div>
  );
}

export default WinnerPage;
