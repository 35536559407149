import React from 'react';
import FlyingParticles from 'components/Particles/FlyingParticles';
import {
  Grid, useTheme, useMediaQuery,
} from '@mui/material';

function FormImageWrapper({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Grid item xs={12}>
        {children}
      </Grid>
    );
  }
  return (
    <Grid sx={{ margin: 'auto', height: '100vh' }} container>
      <Grid item xs={12} md={4} lg={3}>
        {children}
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={9}
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
        }}
      >
        <img style={{ zIndex: 1 }} src="/images/quiz.png" alt="quiz game" />
        <FlyingParticles />
      </Grid>
    </Grid>
  );
}

export default FormImageWrapper;
