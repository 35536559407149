import React from 'react';

function TablePots({ tournament }) {
  return (
    <div className="pot_holder" data-testid="pot_holder">
      {
        tournament.pots.map((pot, index) => (
          <div key={`pot-${index + pot}`} data-testid={`pot-${index}`}>
            <p>{Math.floor(pot.potSize)}</p>
            <img src="/images/coins.png" alt="ante" />
          </div>
        ))
      }
    </div>
  );
}

export default TablePots;
