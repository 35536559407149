/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  callButtonText, checkButtonText, foldButtonText, raiseButtonText,
} from 'constants/text/dashboard';
import { Box, Typography } from '@mui/material';

function RulesModal({ onClose }) {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  };
  return (
    <Box>
      <header>
        <Typography variant="h4" textAlign="center" mt={4}>Правила на играта</Typography>
      </header>
      <div className="rules">
        <h3>
          Лигата на знаещите е нов куиз формат, който обединява куиз игра с правилата на турнирния покер.
        </h3>

        <strong>Предварителната спецификация на турнирните правила налага следните ограничения:</strong>
        <ul>
          <li>Брой на играчите: 2 до 9</li>
          <li>Вид на залозите: но лимит</li>
          <img src="/images/cards.png" alt="poker chip" />
          {/* <li>Анте: по избор (задава се от водещия)</li> */}
          <li>Играта продължава до оставането на един играч</li>
          <li>
            Нивата на първоначалните залози на сляпо се променят слeд всеки N въпроса (N се задава от
            водещия)

          </li>
          <li>
            Залозите се извършват в два рунда:
            <ul>
              <li>
                първи рунд: след поставяне на залозите на сляпо и обявяване на темата на въпроса от
                водещия
              </li>
              <li>втори рунд: след задаване на въпроса от водещия</li>
            </ul>
          </li>

          <li>
            Задаването на въпросите и определянето на правилно отговорилите се извършва ръчно от водещия
          </li>
        </ul>

        <strong>Речник на термините и съкращенията</strong>
        <ul>
          {/* <li>
                Анте – първоначален залог, който се прави на сляпо от всички играчи в началото на всяко “раздаване”
                (въпрос)
              </li> */}
          <li>
            Първоначален залог на сляпо (малък и голям) – първоначални залози, които се правят на сляпо от двама
            играчи в началото на всяко “раздаване” (въпрос). За първото раздаване, играчът, който поставя големия
            залог, се определя чрез жребий. Играчът от дясната му страна поставя малкия залог. При следващите
            раздавания, играчите се редуват (големият залог се движи по масата) по посока на часовниковата стрелка.
            За целите на този документ, ще използваме стандартните съкращения:
          </li>
          <li>SB = малък залог на сляпо</li>
          <li>BB = голям залог на сляпо</li>

          <li>
            Пот – общ размер на текущите залози. Например, ако имаме малък залог от 1 чип, голям залог от 2 чипа, и
            покачване до 5 чипа, то размерът на пота е 8 чипа.
          </li>
          {/* <li>
                Лимит – ограничение на максималното покачване до размера на BB в първия рунд, и 2BB във втория рунд
                на залагане. Броят на покачванията е ограничен до общо 4 (4BB в първия рунд, 8BB във втория рунд)
              </li> */}
          <img src="/images/two_chips.png" alt="poker chip" />
          {/* <li>
                Пот лимит – ограничение на максималното покачване до текущия размер на пота. Например, ако има само
                малък и голям залог на сляпо на масата (1 + 2 = общо 3 чипа), то максималното покачване е 3 чипа,
                съответно следващия играч може да покачи максимум до 5 чипа (2 чипа, за да покрие текущия голям залог
                на сляпо, + 3 чипа покачване). Съответно, следващият играч след него ще може да покачи максимум до 13
                чипа (5 чипа, за да покрие залога на предния играч, + 8 чипа покачване, защото толкова е размера на новия
                пот).
              </li> */}
          <li>
            Но лимит – без ограничение в размера на покачванията. Играч може да влезе “ол ин” (all in) по всяко време,
            когато е негов ред да залага, с всички чипове, с които разполага.
          </li>
          <li>Стак – броят чипове, които притежава даден играч в момента. </li>
          <li>Начален стак – броят чипове, с които всеки играч започва играта.</li>
          <li>
            {checkButtonText}
            {' '}
            – когато няма покачване и е твой ред, означава да останеш в играта, без да заложиш.
          </li>
          <li>
            {callButtonText}
            {' '}
            – плащане на текущия залог, без покачване.
          </li>
          <li>
            {raiseButtonText}
            {' '}
            – покачване на залога.
          </li>
          <li>
            {foldButtonText}
            {' '}
            – отказване да се плати текущия залог, и излизане от играта.
          </li>
        </ul>
      </div>
    </Box>
  );
}

export default RulesModal;
