import React, { useState } from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import Loading from 'components/Loading/Loading';
import HistoryLog from 'components/Dashboard/HistoryLog';
import useFetch from 'hooks/useFetch';
import { HISTORY_LOGS_URL, ASKED_QUESTIONS_URL, UNASKED_QUESTIONs_URL } from 'constants/apiRoutes';
import {
  Box, Button, CircularProgress, Paper, Stack, Typography,
} from '@mui/material';
import AskedQuestionsList from 'components/AskedQuestionsList/AskedQuestionsList';
import errorToast from 'components/toasts/errorToast';

function WinnerModal() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [winner, setWinner] = useState(null);
  const { tournamentId } = useParams();

  const { data: historyLogs, isLoading } = useFetch(`${HISTORY_LOGS_URL}/${tournamentId}`, {
    isProtected: true,
    onError: () => {
      navigate('/404');
    },
    onSuccess: () => {
      try {
        const { winners } = state;
        if (winners !== undefined && historyLogs !== undefined) {
          setWinner(winners);
        }
      } catch {
        navigate('/404');
      }
    },
  });

  const {
    data: askedQuestions,
    isLoading: areQuestionLoading,
  } = useFetch(`${ASKED_QUESTIONS_URL}/${tournamentId}`, {
    isProtected: true,
    onError: () => {
      errorToast('Изникна грешка при зареждането на въпросите');
    },
  });

  const {
    data: unaskedQuestions,
    isLoading: areUnaskedQuestionsLoading,
  } = useFetch(`${UNASKED_QUESTIONs_URL}/${tournamentId}`, {
    onError: () => {
      errorToast('Изникна грешка при зареждането на не зададените въпроси');
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack sx={{ zIndex: 100 }} alignItems="center" pb={3} spacing={2}>
      {
          winner.length === 1
          && (
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            p={3}
            flexWrap="wrap"
          >
            <Typography variant="h4">Победител</Typography>
            <Box
              sx={{
                width: '100%',
              }}
            >

              <img
                src={`/images/avatars/avatar-${winner[0].avatarId}.png`}
                alt="winner avatar"
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <Typography variant="h5">{winner[0].name}</Typography>
            <Link to="/user">
              <Button variant="contained">Към профила</Button>
            </Link>
          </Stack>
          )
        }
      {
          winner.length > 1
          && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '50%' }}
            spacing={3}
            flexWrap="wrap"
          >
            <Typography variant="h4">Победители</Typography>
            {
              winner.map((winnerObject) => (
                <Stack justifyContent="center" alignItems="center" direction="row" spacing={2} sx={{ width: '80%' }} key={winnerObject.id}>
                  <img src={`/images/avatars/avatar-${winnerObject.avatarId}.png`} alt="winner avatar" style={{ width: '12%' }} />
                  <Typography variant="h5">{winnerObject.name}</Typography>
                </Stack>
              ))
            }
            <Link to="/user">
              <Button variant="contained">Към профила</Button>
            </Link>
          </Stack>
          )
        }

      <Stack
        sx={{
          width: {
            xs: '90%',
            md: '50%',
            lg: '30%',
          },
        }}
        spacing={3}
      >
        <AskedQuestionsList
          title="Зададени стандартни въпроси"
          items={askedQuestions?.data}
          type="standard"
          isLoading={areQuestionLoading}
        />
        <AskedQuestionsList
          title="Зададени тайбрейк въпроси"
          items={askedQuestions?.data}
          type="tiebreak"
          isLoading={areQuestionLoading}
        />
        <Typography textAlign="center" variant="h5">Избрани въпроси които не са зададени</Typography>
        <Paper>
          {
              areUnaskedQuestionsLoading
                ? (
                  <Stack justifyContent="center" alignItems="center">
                    <CircularProgress />
                  </Stack>
                )
                : (
                  <Stack direction="row" p={2}>
                    {
                      unaskedQuestions?.data?.length !== 0
                        ? unaskedQuestions?.data?.map((question, index) => (
                          <Box key={question.number}>
                            {question.number}

                            {index !== unaskedQuestions.data.length - 1 ? ', ' : null}
                          </Box>
                        ))
                        : <Typography sx={{ width: '100%' }} textAlign="center">Няма незададени въпроси</Typography>
                    }
                  </Stack>
                )
            }
        </Paper>
        <Stack
          sx={{
            height: '50vh',
          }}
        >
          <HistoryLog
            title={<Typography textAlign="center" variant="h5">История на активността</Typography>}
            historyLogs={historyLogs?.data || []}
            disableAutoScroll
          />
        </Stack>

      </Stack>
    </Stack>
  );
}

export default WinnerModal;
