import React from 'react';
import useBetting from 'hooks/useBetting';
import useControls from 'hooks/useControls';
import RaiseAndCallButtons from 'components/Dashboard/RaiseAndCallButtons';
import BetAndCheckButtons from 'components/Dashboard/BetAndCheckButtons';
import PotAndHalfPotButtons from 'components/Dashboard/PotAndHalfPotButtons';
import RaiseSlider from 'components/Inputs/RaiseSlider';
import BettingContext from 'context/BettingContext';

function BettingButtons() {
  const {
    raise,
    callValue,
    shouldBlockAllInButton,
    setRaise,
  } = useBetting();

  const {
    showCheckAndBetButtons,
    isRequestResolved,
    handleMove,
  } = useControls();

  return (
    <BettingContext.Provider
      value={{
        callValue,
        raise,
        shouldBlockAllInButton,
        isRequestResolved,
        handleMove,
        setRaise,
      }}
    >
      <div className="betting_buttons">
        {
          raise.isPossible
            && (
            <>
              <PotAndHalfPotButtons />
              <RaiseSlider />
            </>
            )
        }
        {
          showCheckAndBetButtons === true
          && <BetAndCheckButtons />
        }

        {
          showCheckAndBetButtons === false
          && <RaiseAndCallButtons />
        }
      </div>
    </BettingContext.Provider>
  );
}

export default BettingButtons;
