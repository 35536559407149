export const TOURNAMENT_STATE_VALUES = [
  // {
  //   name: 'Пот лимит',
  //   value: 2,
  // },
  // {
  //   name: 'Лимит',
  //   value: 1,
  // },
  {
    name: 'Но лимит',
    value: 3,
  },
];

export const STARTING_LEVEL_VALUES = [
  {
    value: '1/2',
    name: '1/2',
  },
  {
    value: '10/20',
    name: '10/20',
  },
  {
    value: '500/1000',
    name: '500/1000',
  },
];
