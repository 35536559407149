import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import { halfRaiseText } from 'constants/text/dashboard';
import { Button } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function HalfRaiseButton({ halfRaise, buttonMobileStyles }) {
  const {
    raise,
    setRaise,
  } = useContext(BettingContext);

  const isMobile = useIsMobile();
  const roundedHalfRaise = Math.floor(halfRaise.value);
  const isButtonDisabled = halfRaise.isPossible === false
  || raise.isPossible === false
  || halfRaise.value < raise.min;

  const setRaiseToHalf = () => {
    setRaise((prevRaise) => ({ ...prevRaise, value: halfRaise.value }));
  };

  return (
    <Button
      type="button"
      disabled={isButtonDisabled}
      onClick={setRaiseToHalf}
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}

    >
      {halfRaiseText}
      {' '}
      {isButtonDisabled === false && `(${roundedHalfRaise})`}
    </Button>
  );
}

export default HalfRaiseButton;
