import TOURNAMENT_STATUS from 'constants/tournamentStatus';
import TournamentContext from 'context/TournamentContext';
import React, {
  useContext, useRef, useState,
} from 'react';

function TopicAndQuestion() {
  const { tournament } = useContext(TournamentContext);
  const {
    question = 'test',
    questionTopic,
    tiebreakQuestion,
    currentStandardQuestion,
    currentTiebreakQuestion,
  } = tournament.handState ?? {};

  const holderRef = useRef();
  const [showHeader, setShowHeader] = useState(false);

  const toogleMinimized = () => {
    if (holderRef) {
      holderRef.current.classList.toggle('hidden_body');
      setShowHeader(holderRef.current?.classList.contains('hidden_body'));
    }
  };

  return (
    <>
      {tournament.tournamentStatusId === TOURNAMENT_STATUS.IN_GAME
    && (
      <div className="topic_and_question">
        <div className="minimize" onClick={toogleMinimized} />
        {showHeader && <header className="header">Тема и Въпрос</header>}
        <div className="holder" ref={holderRef}>
          <div className="topic">
            <h3>Тема</h3>
            <p id="topic">{questionTopic || 'Все още не е зададена'}</p>
          </div>
          <div className="question">
            <h3>
              Въпрос
              {currentStandardQuestion && ` No:${currentStandardQuestion}`}
            </h3>
            <p id="question">
              {question || 'Все още не е зададен'}
            </p>
          </div>
          {
            currentTiebreakQuestion
            && (
            <div className="question">
              <h3 style={{ color: '#6658D3' }}>
                Тайбрейк Въпрос
                {currentTiebreakQuestion && ` No:${currentTiebreakQuestion}`}
              </h3>
              <p id="question">
                {tiebreakQuestion || 'Все още не е зададен'}
              </p>
            </div>
            )
          }
        </div>
      </div>
    )}
    </>
  );
}

export default TopicAndQuestion;
