import useTimer from 'hooks/useTimer';
import React, { useEffect, useRef } from 'react';
import calculatePercentage from 'utilis/calculatePercentage';

function Timer({
  initialTime, onTimeOut, maxTime, options,
}) {
  const { time } = useTimer({ initialTime, onTimeOut });

  const {
    left, right, top, bottom,
  } = options;

  let classNames = 'timer_holder';

  if (left) {
    classNames += ' left';
  }

  if (right) {
    classNames += ' right';
  }

  if (top) {
    classNames += ' top';
  }

  if (bottom) {
    classNames += ' bottom';
  }

  const timerRef = useRef(null);

  useEffect(() => {
    if (timerRef.current) {
      if (left || right) {
        const percentage = calculatePercentage(time, maxTime || initialTime);
        timerRef.current.style.height = `${percentage}%`;
      }
      if (top || bottom) {
        const percentage = calculatePercentage(time, maxTime || initialTime);
        timerRef.current.style.width = `${percentage}%`;
      }
    }
  }, [time]);

  return (
    <>
      {time > 0
      && (
      <div className={classNames}>
        <div ref={timerRef} className="timer_body" />
      </div>
      )}
    </>
  );
}

export default Timer;
