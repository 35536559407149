import React from 'react';
import { Paper, Stack, useMediaQuery } from '@mui/material';
import Owner from 'components/GamePending/Owner';
import InfoSection from 'components/GamePending/InfoSection';

function GamePending() {
  const isPortrait = useMediaQuery('(orientation: portrait)');
  return (
    <Paper
      sx={{
        width: '30%',
        height: isPortrait ? 'auto' : '100vh',
      }}
      elevation={2}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        py={5}
        px={2}
      >
        <Owner />
        <InfoSection />
      </Stack>
    </Paper>
  );
}

export default GamePending;
