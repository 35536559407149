const getButtonPositionIndex = (players) => {
  let buttonIndex = 0;

  // if only 2 players the button is in the SB
  if (players.length === 2) {
    return players[0].isSmallBlind === 1 || players[0].isSmallBlind === true ? 0 : 1;
  }

  players.forEach((player, index) => {
    if (player.isSmallBlind === true || player.isSmallBlind === 1) {
      if (index - 1 < 0) {
        buttonIndex = players.length - 1;
      } else {
        buttonIndex = index - 1;
      }
    }
  });

  return buttonIndex;
};

export default getButtonPositionIndex;
