const topics = [
  'Култура',
  'Забавления',
  'История',
  'Лайфстайл',
  'Медия',
  'Наука',
  'Спорт и игри',
  'Свят',
];

export default topics;
