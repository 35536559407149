import React, { useState } from 'react';
import { announceTopicButtonText } from 'constants/text/dashboard';
import TextInputModal from 'components/Modals/TextInputModal';
import TopicsSelect from 'components/Inputs/TopicsSelect';
import { LoadingButton } from '@mui/lab';
import useIsAuto from 'hooks/useIsAuto';

function TopicAnnouncementButton({ isRequestResolved, triggerNextTournamentState }) {
  const [showModal, setShowModal] = useState(false);

  useIsAuto({
    chooseTopicFunction: triggerNextTournamentState,
  });

  const openTextInputModal = () => {
    setShowModal(true);
  };

  const closeModal = (text) => {
    setShowModal(false);
    triggerNextTournamentState({
      questionTopic: text,
    });
  };

  return (
    <>
      {showModal
      && (
      <TextInputModal
        headerText="Задай тема"
        onClose={closeModal}
      >
        <TopicsSelect />
      </TextInputModal>
      )}

      <LoadingButton
        onClick={openTextInputModal}
        loading={isRequestResolved === false}
        id="announce_topic"
        type="button"
        fullWidth
        variant="contained"
      >
        {announceTopicButtonText}
      </LoadingButton>
    </>
  );
}

export default TopicAnnouncementButton;
