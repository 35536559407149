import {
  Stack, Paper, Button, Typography, Grid,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import useIsMobile from 'hooks/useIsMobile';
import useIsOwner from 'hooks/useIsOwner';
import TOURNAMENT_STATES from 'constants/tournamentStates';
import TournamentContext from 'context/TournamentContext';
import BlurryBackground from 'components/Modals/BlurryBackground';

function ChoseQuestionNumberModal({ questions = [], onPick }) {
  const [disabled, setDisabled] = useState(false);
  const { tournament } = useContext(TournamentContext);
  const { stateId } = tournament.handState;
  const { players } = tournament;

  const isMobile = useIsMobile();

  const isOwner = useIsOwner();

  const onPickNumber = (question) => {
    onPick(question);
    setDisabled(true);
  };

  const getPlayerName = () => {
    if (stateId === TOURNAMENT_STATES.STANDART_QUESTION_NUMBER_CHOOSING) {
      const bigBlindPlayer = players.find((player) => {
        if (player.isBigBlind === true || player.isBigBlind === 1) {
          return true;
        }
        return false;
      });

      return bigBlindPlayer.name;
    }

    if (stateId === TOURNAMENT_STATES.TIEBREAK_QUESTION_NUMBER_CHOOSING) {
      const lastPlayerInSecondHand = JSON.parse(localStorage.getItem('lastPlayerInSecondHand'));
      const lastPlayer = players.find((player) => {
        if (Number(player.userId) === Number(lastPlayerInSecondHand.userId)) {
          return true;
        }
        return false;
      });

      return lastPlayer.name;
    }
    return '';
  };

  return (
    <BlurryBackground>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Paper
          elevation={2}
          sx={{
            width: isMobile ? '100%' : '60%',
            height: isMobile ? '100%' : '80%',
            p: 2,
            overflowY: 'auto',
            position: 'relative',
            zIndex: 10,
          }}
        >
          {
            isOwner
              ? (
                <Typography variant="h6" textAlign="center">
                  Играч
                  {' '}
                  {getPlayerName()}
                  {' '}
                  избира въпрос
                </Typography>
              )
              : <Typography variant="h6" textAlign="center">Избери номер на въпрос</Typography>
          }
          <Grid container mt={2} alignItems="center" justifyContent="center">
            {
            questions.map((question) => (
              <Grid item xs={1.2} key={question.number} mb={2} alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  disabled={question.disabled || disabled}
                  sx={{
                    textDecoration: question.disabled ? 'line-through' : 'auto',
                    minWidth: '10px',
                    width: '80%',
                    marginLeft: '10%',
                  }}
                  onClick={() => onPickNumber(question)}
                >
                  {question.number}
                </Button>
              </Grid>
            ))
          }
          </Grid>
        </Paper>
      </Stack>
    </BlurryBackground>
  );
}

export default ChoseQuestionNumberModal;
