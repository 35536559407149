import { useEffect, useState } from 'react';
import axiosInstance from 'axiosInstance';

const useFetch = (url, {
  method = 'GET',
  fetchOnMount = true,
  onSuccess,
  onError,
  onSettled,
}) => {
  const [data, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = (dataToSend) => {
    setIsLoading(true);
    axiosInstance(url, {
      data: dataToSend || null,
      method,
    })
      .then((res) => {
        if (onSuccess) {
          onSuccess(res.data);
        }
        setResponseData(res.data);
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }

        setIsError(true);
        if (error.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError('Нещо се обърка');
        }
      })
      .finally(() => {
        if (onSettled) {
          onSettled();
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fetchOnMount === true) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [url]);

  return {
    data,
    error,
    isError,
    isLoading,
    fetchData,
  };
};

export default useFetch;
