import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import PotRaiseButton from 'components/Buttons/PotRaiseButton';
import HalfRaiseButton from 'components/Buttons/HalfRaiseButton';
import usePotAndHalfRaise from 'hooks/usePotAndHalfRaise';
import { Button, ButtonGroup } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function PotAndHalfPotButtons() {
  const { raise, setRaise } = useContext(BettingContext);
  const { potRaise, halfRaise } = usePotAndHalfRaise();

  const setMinRaise = () => {
    setRaise((prevRaise) => ({ ...prevRaise, value: prevRaise.min }));
  };

  const setMaxRaise = () => {
    setRaise((prevRaise) => ({ ...prevRaise, value: prevRaise.max }));
  };

  const isMobile = useIsMobile();

  const buttonMobileStyles = {
    fontSize: '11px', p: 0, m: 0,
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>

      <Button
        type="button"
        onClick={setMinRaise}
        disabled={raise.isPossible === false}
        fullWidth
        variant="contained"
        sx={isMobile ? buttonMobileStyles : null}
      >
        Мин
      </Button>

      <HalfRaiseButton halfRaise={halfRaise} buttonMobileStyles={buttonMobileStyles} />

      <PotRaiseButton potRaise={potRaise} buttonMobileStyles={buttonMobileStyles} />

      <Button
        type="button"
        onClick={setMaxRaise}
        disabled={raise.isPossible === false}
        fullWidth
        variant="contained"
        sx={isMobile ? buttonMobileStyles : null}
      >
        Макс
      </Button>
    </ButtonGroup>
  );
}

export default PotAndHalfPotButtons;
