import React, { useContext, useEffect, useState } from 'react';
import TournamentStateTable from 'components/Dashboard/TournamentStateTable';
import HistoryLog from 'components/Dashboard/HistoryLog';
import Controls from 'components/Dashboard/Controls';
import TournamentContext from 'context/TournamentContext';
import AuthContext from 'context/AuthContext';
import getShowControls from 'utilis/getShowControls';
import { Stack } from '@mui/material';

function Dashboard() {
  const { tournament } = useContext(TournamentContext);
  const { user } = useContext(AuthContext);
  const { historyLogs, players, owner } = tournament;
  const { stateId } = tournament.handState;
  const [showControls, setShowControls] = useState();

  useEffect(() => {
    setShowControls(getShowControls(players, user, owner, stateId));
  }, [players, stateId]);

  return (
    <Stack className="dashboard_holder">

      <TournamentStateTable />
      <HistoryLog historyLogs={historyLogs} />

      {
        showControls && <Controls />
      }
    </Stack>
  );
}

export default Dashboard;
