import React, { useState } from 'react';
import TournamentStats from 'components/User/TournamentStats';
import {
  Box, Typography, Tabs, Tab, Stack,
} from '@mui/material';
import GameStatsHeader from 'components/User/TournamentStatsHeader';

function JoinTournamentSection() {
  const [value, setValue] = useState('not_in_game');

  return (
    <Stack px={{ md: 6, xs: 0 }} sx={{ height: '100%' }}>
      <Typography variant="h5" textAlign="center" pt={3}>Влез в игра</Typography>
      <Box sx={{
        borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center',
      }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={(e, newValue) => { setValue(newValue); }}
        >
          <Tab wrapped label="Турнири, в които съм директор" value="owner" />
          <Tab wrapped label="Турнири, в които съм играч" value="player" />
          <Tab wrapped label="Турнири, в които не участвам" value="not_in_game" />
        </Tabs>
      </Box>

      <GameStatsHeader />
      <TournamentStats tournamentsToShow={value} />
    </Stack>
  );
}

export default JoinTournamentSection;
