import React, { useEffect, useRef, useState } from 'react';

const useTimer = ({ initialTime, onTimeOut }) => {
  const [time, setTime] = useState(initialTime);

  const timeIntervalRef = useRef(null);
  useEffect(() => {
    timeIntervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    setTime(initialTime);
    return () => {
      clearInterval(timeIntervalRef.current);
    };
  }, [initialTime]);

  useEffect(() => {
    if (time <= 0) {
      if (onTimeOut) {
        onTimeOut();
      }

      clearInterval(timeIntervalRef.current);
    }
  }, [time]);

  return { time };
};

export default useTimer;
