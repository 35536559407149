import {
  Drawer, Box, useMediaQuery, Stack, Button,
} from '@mui/material';
import React, { useContext } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthContext from 'context/AuthContext';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { useNavigate } from 'react-router-dom';
import CreateYourTournamentSection from './CreateYourTournamentSection';

function SideNav({ open, onClose }) {
  const navigate = useNavigate();
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { logOut } = useContext(AuthContext);

  return (
    <Drawer
      anchor="left"
      open={isLarge ? true : open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'rgb(28,37,54)',
          color: 'common.white',
          width: isLarge ? '19%' : '80%',
        },
      }}
      variant={isLarge ? 'permanent' : 'temporary'}
    >
      <Box
        sx={{
          px: 2,
          py: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <CreateYourTournamentSection />
        <Stack sx={{ pb: 2, mt: 'auto' }} spacing={2}>
          <Button
            onClick={() => { navigate('rules'); onClose(); }}
            variant="contained"
            fullWidth
            startIcon={
              <AutoStoriesIcon />
          }
          >
            Правила
          </Button>
          <Button
            onClick={() => { navigate('tournaments'); onClose(); }}
            variant="contained"
            fullWidth
            startIcon={
              <SportsEsportsIcon />
          }
          >
            Турнири
          </Button>
          <Button
            onClick={logOut}
            variant="outlined"
            fullWidth
            color="inherit"
            startIcon={
              <LogoutIcon />
            }
          >
            Изход
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default SideNav;
