import React, { useContext } from 'react';
import BettingContext from 'context/BettingContext';
import { potRaiseText } from 'constants/text/dashboard';
import { Button } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';

function PotRaiseButton({ potRaise, buttonMobileStyles }) {
  const {
    raise,
    setRaise,
  } = useContext(BettingContext);

  const isMobile = useIsMobile();
  const roundedPotRaise = Math.floor(potRaise.value);
  const isButtonDisabled = potRaise.isPossible === false
   || raise.isPossible === false
   || potRaise.value < raise.min;

  const setRaiseToPot = () => {
    setRaise((prevRaise) => ({ ...prevRaise, value: potRaise.value }));
  };

  return (
    <Button
      type="button"
      disabled={isButtonDisabled}
      onClick={setRaiseToPot}
      fullWidth
      variant="contained"
      sx={isMobile ? buttonMobileStyles : null}

    >
      {potRaiseText}
      {' '}
      {isButtonDisabled === false && `(${roundedPotRaise})`}
    </Button>
  );
}

export default PotRaiseButton;
