import React from 'react';

function Loading() {
  return (
    <div className="lds-ring" data-testid="loading">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default Loading;
