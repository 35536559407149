const getCallValue = (highestBet, currentBet, currentStack, bigBlind) => {
  if (highestBet >= bigBlind) {
    if (highestBet - currentBet > currentStack) {
      return currentStack;
    }
    return highestBet - currentBet;
  }

  if (highestBet < bigBlind) {
    if (bigBlind > currentStack) {
      return currentStack;
    }
    return bigBlind - currentBet;
  }

  return null;
};

export default getCallValue;
