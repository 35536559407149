import { Button, Stack, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

function CreateYourTournamentSection() {
  const { user } = useContext(AuthContext);

  return (
    <Stack
      spacing={4}
      alignItems="center"
      sx={{
        height: 'fit-content', pt: { xs: 5, md: 0 }, pb: 5,
      }}
    >
      <img
        src={`/images/avatars/avatar-${user?.avatarId}.png`}
        alt="your avatar"
        style={{ width: '40%', height: 'auto', borderRadius: '50%' }}
      />

      <Typography variant="h5">{user?.username}</Typography>
      <Link to="/createTournament">
        <Button
          variant="contained"
          type="button"
          sx={{
            fontSize: {
              xs: '11px',
              md: '12px',
            },
          }}
        >
          Създай свой турнир
        </Button>
      </Link>
    </Stack>
  );
}

export default CreateYourTournamentSection;
