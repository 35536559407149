/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

function FlyingParticles({ x, y }) {
  const numParticles = 100;
  const [particles, setParticles] = useState(Array(numParticles).fill(0));

  return (
    <div className="particle_holder">
      {
        particles.map((particle, index) => <img key={index} className="particle" alt="particle" src="/images/particle_coin.png" />)
      }
    </div>
  );
}

export default FlyingParticles;
