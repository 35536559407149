import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading/Loading';
import useProtected from 'hooks/useProtected';
import {
  Grid, IconButton, Stack, useMediaQuery,
} from '@mui/material';
import SideNav from 'components/User/SideNav';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useNavigate } from 'react-router-dom';

function UserPage() {
  const { isLoading } = useProtected({ redirectUrl: '/', hasToBeLogged: true });
  const [openNav, setOpenNav] = useState(false);
  const isLargeDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const navigate = useNavigate();
  useEffect(() => {
    navigate('tournaments');
  }, []);
  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack height={{ md: '100vh' }} justifyContent="center" alignItems="center">
      {isLargeDown
      && (
      <IconButton
        onClick={() => setOpenNav(true)}
        sx={{
          alignSelf: 'flex-start',
        }}
      >
        <MenuIcon />
      </IconButton>
      )}

      <Grid
        container
        sx={{
          borderRadius: '20px',
          width: { md: '90%', xs: '100%' },
          height: '100%',
        }}
      >
        <Grid item xs={2} sx={{ height: '100%' }}>
          <SideNav onClose={() => { setOpenNav(false); }} open={openNav} />
        </Grid>
        <Grid item xs={isLargeDown ? 12 : 10} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default UserPage;
